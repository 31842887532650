import React, { useEffect } from 'react'
import ChatRoom from './ChatRoom';
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import { useUser } from '../../hooks/useUser';
import { UserEntity } from '../../../../backend/src/shared/entity';
import { Link, useParams } from 'react-router-dom';
import Fa from '../FontAwesome';
import { useWebSocket } from '../../hooks/useWebSocket';
import useMessages from '../../hooks/useMessages';
import Avatar from '../Avatar';
import Title from '../Title';

const Chat = () => {
  const { uuid } = useParams();
  const { user } = useUser();
  const [roommate, setRoommate] = React.useState<Roommate | null>(null);
  const [online, setOnline] = React.useState<Array<string>>([]);
  const socket = useWebSocket();
  const { messages } = useMessages();
  const { data: roommates } = useQuery({
    queryKey: ['roommatesQuery', user.uuid],
    queryFn: () => sql.roommatesQuery({ loginUuid: user.uuid }) as Promise<Array<Roommate>>,
    enabled: !!user.uuid,
  });
  if (uuid && roommates && !roommate) {
    setRoommate(roommates?.find(r => r.Uuid === uuid) || null);
  }
  if (roommate && !uuid) setRoommate(null);
  const onlineFirst = (a: Roommate, b: Roommate) => {
    if (online.includes(a.Uuid) && !online.includes(b.Uuid)) return -1;
    if (!online.includes(a.Uuid) && online.includes(b.Uuid)) return 1;
    return 0;
  }

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      const [action, data] = event.data.split(':');
      if (action === 'online') {
        setOnline(JSON.parse(data));
      }
    };
    let interval = setInterval(() => {
      socket.send('online');
      socket.addEventListener('message', onMessage);
    }, 2000); // refresh online users every 2 seconds

    return () => { clearTimeout(interval); socket.removeEventListener('message', onMessage) };

  }, [socket]);

  return (
    <>
      {roommate ? <ChatRoom {...roommate} /> : (
        <div>
          <ul className="nav flex-column">
            <li>
              <Title text='Discuție' />
              <hr />
            </li>

            {roommates?.map(r => (
              <li key={r.Uuid} className='nav-item'>
                <Link to={`/chat/${r.Uuid}`} className='nav-link position-relative'>
                  <Avatar src={r.Avatar} /> <span className={messages[r.Uuid] ? 'fw-bold' : ''}>{r.Name}</span>
                  {messages[r.Uuid] > 0 && <span className="badge bg-danger rounded-pill position-absolute top-0 start-100">{messages[r.Uuid]}</span>}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}
type Roommate = Pick<UserEntity, "Avatar" | "Uuid" | "Name" | "Email">;

export default Chat