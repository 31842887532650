import React from 'react'
import Title from '../Title'

const MentoringIs = () => {
    return (
        <div>
            <Title text='Ce înseamnă mentorarea?' />
            <p>
                Mentoring is any form of social learning where someone with more experience (a mentor) helps guide the learning and growth of someone with less experience (a mentee). It is a learning relationship which helps people to take charge of their own development, to release their potential and to achieve results which they value.
            </p>
            <p>
                There are many definitions of mentoring. However, the most succinct definition is when someone shares their knowledge, skills, and experience to help another person to progress. This progress could be professional or personal, although given the work/life balance, the crossover between the two is often considered. The mentor acts as a trusted advisor and role model, sharing his/her expertise and helping the mentee develop their skills, knowledge, and abilities.
            </p>
            <p>
                Mentoring is a relationship. It is a relationship between the mentor and the mentee. Mentoring is defined as a one-to-one relationship in which a more experienced person voluntarily gives time to teach, support, and encourage another. The concept of mentoring relates to emotional support and guidance usually given by a mature individual to a younger person. There is much refining of the definition in the recent literature on mentoring. Mentoring passes on knowledge of subjects, facilitates personal development, encourages wise choices, and helps the mentee to make transitions.
            </p>
        </div>
    )
}

export default MentoringIs