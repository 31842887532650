import React from 'react'
import Title from '../Title'
import { Labels } from '../../common/lang'

const MentorPlus = () => {
    return (
        <div>
            <Title text={"Mentor+" as Labels} />
            <body className="container mt-4">
                <h3>Mentor+ Project</h3>
                <p>
                    Mentor+ Project offers an intervention of non-formal education to engage,
                    connect and empower young people in conflict with the law. More
                    specifically, the project creates a mentoring model that employs
                    law-abiding role model adults to support juveniles in conflict with the
                    law in the process of developing positive attitudes and behaviours. The
                    project aims accomplish this by three offers:
                </p>
                <p>
                    Designing & piloting a mentoring programme for justice-involved youth in
                    order to avoid their incarceration/ institutionalization, targeting at
                    least 60 young people in conflict with the law in 6 Member States.
                </p>
                <p>
                    Creating a Handbook for the Mentors to support them in mentoring
                    justice-involved Youth (providing guidelines, templates and useful tools
                    to put into motion during the mentoring sessions).
                </p>
                <p>
                    Creating and piloting a mobile application, available in 6 different
                    European languages to support mentors and mentees in real time, giving
                    visibility to the added value of the electronic nature of e-mentoring as a
                    mechanism that allows mentoring relationships to transcend geographical
                    and temporal boundaries.
                </p>
                <h3>Youth within the Juvenile Justice System</h3>
                <p>
                    Youth within the juvenile justice system (JJS) are among the most
                    vulnerable citizens. Research shows that incarceration does not attend to
                    their needs, and it often results in negative behavioural & mental health
                    consequences (e.g. maintain, or even increase, levels of engagement in
                    anti-social behaviour and criminal activity)(Lambie & Randell, 2013).
                    Youth within the JJS continue to experience routine violations of their
                    basic rights, including violence and isolation within detention centers
                    (Souverein et al., 2019). Moreover, the relationship between juvenile
                    delinquency & school dropout, mental health problems, substance abuse,
                    unemployment, illegal lifestyle, a higher risk of intergenerational
                    perpetuation of patterns and internalizing disorders (e.g. depression and
                    anxiety) is backboned by research.
                </p>
                <h3>Why Mentoring as Methodology?</h3>
                <p>
                    Lipsey et al.’s “Improving the Effectiveness of Juvenile Justice Programs”
                    reflects results of meta-analyses on effectiveness of programs to
                    rehabilitate juvenile offenders (2010, p.12). Accordingly:
                </p>
                <p>
                    For juvenile offenders in general, the juvenile justice supervisory
                    apparatus of probation and court monitoring, group homes, correctional
                    facilities, and the like has, at best, only modest favourable effects on
                    subsequent recidivism and some evidence shows modest negative effects
                    (Lipsey and Cullen, 2007; Petrosino, Turpin-Petrosino, and Guckenburg,
                    2010).
                </p>
                <p>
                    Deterrence-oriented programs that focus on discipline, surveillance, or
                    threat of punitive consequences (e.g. prison visitation Scared
                    Straight–type programs, boot camps, and intensive probation supervision)
                    on average have no effect on recidivism and may increase it (Lipsey,
                    2009).
                </p>
                <p>
                    Many “therapeutic” programs oriented toward facilitating constructive
                    behavior change have shown very positive effects—even for serious
                    offenders (Lipsey, 2009; Lipsey and Cullen, 2007; Lipsey and Wilson,
                    1998).”
                </p>

                <p>
                    Along the same lines, Lipsey’s comprehensive meta-analysis on the effects
                    of delinquency interventions created a comprehensive database that
                    included 548 studies conducted between 1958 through 2002. As you can see
                    below in Figure 1, punitive approaches actually have even negative impact
                    on recidivism of the target groups whereas therapeutic approaches can
                    clearly be observed to contribute consequentially to recidivism and
                    positive development (Lipsey, 2009, p.24).
                </p>

                <div className="col-xl text-center">
                    <p className="text-muted">
                        <i>Figure 1. Mean recidivism effect for the program categories</i>
                    </p>
                    <img src="/images/Figure-1.png" className="img-fluid" alt="" />
                </div>

                <p className="mt-5">
                    The consequentiality of therapeutic approaches being said, “Counseling”
                    category can be said to be the most effective among others. A deeper study
                    within this category shows even greater differentiation among different
                    methodologies bringing change in the youth’s life. Among different kinds
                    of counselling, as can be seen in Figure 2 below, “group counselling” and
                    “mentoring” are the two most promising methodologies (Lipsey, 2009, p.25).
                </p>
                <div className="col-xl text-center">
                    <p className="text-muted">
                        <i>Figure 2. Mean recidivism effects for different methodologies within
                            the counseling category</i>
                    </p>
                    <img src="/images/Figure-2.png" className="img-fluid" alt="" />
                </div>

                <h3 className="mt-5">Resources</h3>
                <ul>
                    <li>
                        Lipsey et al. offenders (2010). Improving the Effectiveness of Juvenile
                        Justice Programs. Center for Juvenile Justice Reform.
                    </li>
                    <li>
                        Souverein, F. et al. (2019). Overview of European forensic youth care:
                        Towards an integrative mission for prevention and intervention
                        strategies for juvenile offenders. Child and Adolescent Psychiatry and
                        Mental Health, 13(1), 6. <a href="https://doi.org/10.1186/s13034-019-0265-4">https://doi.org/10.1186/s13034-019-0265-4</a>.
                    </li>
                    <li>
                        Lambie, I., and Randell, I. (2013) . The impact of incarceration on
                        juvenile offenders. Clinical Psychology Review, Volume 33, Issue 3,
                        448-459, ISSN 0272-7358, <a href="https://doi.org/10.1016/j.cpr.2013.01.007">https://doi.org/10.1016/j.cpr.2013.01.007</a> .
                    </li>
                    <li>
                        Lipsey, M . W ., and Cullen, F . T . (2007) . The effectiveness of
                        correctional rehabilitation: A review of systematic reviews . Annual
                        Review of Law and Social Science, 3, 297–320 .
                    </li>
                    <li>
                        Petrosino, A ., Turpin-Petrosino, C ., and Guckenburg, S . (2010) .
                        Formal system processing of juveniles: Effects on delinquency . Campbell
                        Systematic Reviews (www . campbellcollaboration .org) .
                    </li>
                    <li>
                        Lipsey, M . W . (2009) . The primary factors that characterize effective
                        interventions with juvenile offenders: A meta- analytic overview .
                        Victims and Offenders, 4, 124–47 .
                    </li>
                    <li>
                        Lipsey, M . W ., and Wilson, D . B . (1998) . Effective intervention for
                        serious juvenile offenders: A synthesis of research . In R. Loeber and D
                        . P . Farrington (Eds .), Serious and violent juvenile offenders: Risk
                        factors and successful interventions (pp . 313–45). Thousand Oaks, CA:
                        Sage Publications, Inc.
                    </li>
                </ul>
            </body>
        </div>
    )
}

export default MentorPlus