import { useLang } from "../hooks/useLang";
import { labels } from "./labels";

export const CountriesSymbol = {
  "nl": "Dutch",
  "en": "English",
  "fr": "French",
  "el": "Greek",
  "it": "Italian",
  "pt": "Portuguese",
  "ro": "Romanian"
};

export const languages: Array<keyof typeof CountriesSymbol> = ["ro", "en", "fr", "it", "pt", "el", "nl"];

export type Language = typeof languages[number];

export type Labels = keyof typeof labels;

export const useTranslate = () => {
  const { lang } = useLang();
  return (label: Labels) =>
    lang === "ro"
      ? label // dacă limba este română, returnează eticheta așa cum este
      : ((labels[label] && (labels as any)[label][lang]) || label as string); // altfel, returnează traducerea dacă există sau eticheta originală
};

export const useLabels = () => {
  return labels;
};

export const MentorResourceLang = {
  "Affirmation Session": {
    "en": "Affirmation Session",
    "ro": "Sesiune de afirmare"
  },
  "Assessment Tool": {
    "en": "Assessment Tool",
    "ro": "Instrument de evaluare"
  },
  "Breathing Sessions": {
    "en": "Breathing Sessions",
    "ro": "Sesiuni de respirație"
  },
  "Dealing with Conflicts and Difficult Behaviors": {
    "en": "Dealing with Conflicts and Difficult Behaviors",
    "ro": "Confruntarea cu conflicte și comportamente dificile"
  },
  "Engaging in Change Talk": {
    "en": "Engaging in Change Talk",
    "ro": "Angajarea în discuții despre schimbare"
  },
  "Identifying Triggers": {
    "en": "Identifying Triggers",
    "ro": "Identificarea declanșatorilor"
  },
  "Mindful Communication": {
    "en": "Mindful Communication",
    "ro": "Comunicare conștientă"
  },
  "Mock MI": {
    "en": "Mock MI",
    "ro": "MI simulat"
  },
  "Reflection to Change Talk": {
    "en": "Reflection to Change Talk",
    "ro": "Reflecție pentru discuții despre schimbare"
  },
  "Simple Reflection": {
    "en": "Simple Reflection",
    "ro": "Reflecție simplă"
  }
};

export const MenteeResourceLang = {
  "ACT_ Recognizing Values and Ideas": {
    "en": "ACT_ Recognizing Values and Ideas",
    "ro": "ACT_ Recunoașterea valorilor și a ideilor"
  },
  "Affirmation Session": {
    "en": "Affirmation Session",
    "ro": "Sesiune de afirmare"
  },
  "Assessment Tool": {
    "en": "Assessment Tool",
    "ro": "Instrument de evaluare"
  },
  "Breathing Sessions": {
    "en": "Breathing Sessions",
    "ro": "Sesiuni de respirație"
  },
  "Building a CV": {
    "en": "Building a CV",
    "ro": "Crearea unui CV"
  },
  "Challenging Negative Thoughts Worksheet": {
    "en": "Challenging Negative Thoughts Worksheet",
    "ro": "Foaie de lucru pentru contestarea gândurilor negative"
  },
  "Changing the Jackal": {
    "en": "Changing the Jackal",
    "ro": "Schimbarea lui Jackal"
  },
  "Cognitive Restructuring Practice": {
    "en": "Cognitive Restructuring Practice",
    "ro": "Practică de restructurare cognitivă"
  },
  "Coping Thoughts Worksheet": {
    "en": "Coping Thoughts Worksheet",
    "ro": "Foaie de lucru pentru gânduri de adaptare"
  },
  "Emotion Journal": {
    "en": "Emotion Journal",
    "ro": ""
  },
  "Empathy Map": {
    "en": "Empathy Map",
    "ro": ""
  },
  "GROW": {
    "en": "GROW",
    "ro": ""
  },
  "Healthy_Unhealthy Relationships - Matching Activity": {
    "en": "Healthy_Unhealthy Relationships - Matching Activity",
    "ro": ""
  },
  "How would you treat a friend": {
    "en": "How would you treat a friend",
    "ro": ""
  },
  "Identifying Triggers": {
    "en": "Identifying Triggers",
    "ro": ""
  },
  "Letter to Future Me": {
    "en": "Letter to Future Me",
    "ro": ""
  },
  "List of Coping Skills": {
    "en": "List of Coping Skills",
    "ro": ""
  },
  "Mindful Communication": {
    "en": "Mindful Communication",
    "ro": ""
  },
  "Mock Interview": {
    "en": "Mock Interview",
    "ro": ""
  },
  "My Safe Place": {
    "en": "My Safe Place",
    "ro": ""
  },
  "Pick a Treat, Tell a Story": {
    "en": "Pick a Treat, Tell a Story",
    "ro": ""
  },
  "Plan of Action": {
    "en": "Plan of Action",
    "ro": ""
  },
  "Positive Pathways Collage": {
    "en": "Positive Pathways Collage",
    "ro": ""
  },
  "Prevent from Boiling Over": {
    "en": "Prevent from Boiling Over",
    "ro": ""
  },
  "Respect": {
    "en": "Respect",
    "ro": ""
  },
  "Stinky Fish": {
    "en": "Stinky Fish",
    "ro": ""
  },
  "The 5-4-3-2-1 Emotional Regulation Technique": {
    "en": "The 5-4-3-2-1 Emotional Regulation Technique",
    "ro": ""
  },
  "The Detective": {
    "en": "The Detective",
    "ro": ""
  },
  "The Endless Cycle": {
    "en": "The Endless Cycle",
    "ro": ""
  },
  "The Jackal": {
    "en": "The Jackal",
    "ro": ""
  },
  "The Power of a Story": {
    "en": "The Power of a Story",
    "ro": ""
  },
  "Thoughts Matter": {
    "en": "Thoughts Matter",
    "ro": ""
  },
  "Through the Scanner": {
    "en": "Through the Scanner",
    "ro": ""
  },
  "Tree of Life": {
    "en": "Tree of Life",
    "ro": ""
  },
  "Unhealthy Relationships - Quiz": {
    "en": "Unhealthy Relationships - Quiz",
    "ro": ""
  },
  "Values": {
    "en": "Values",
    "ro": ""
  },
  "What to do in times of stress": {
    "en": "What to do in times of stress",
    "ro": ""
  },
  "Wheel of Emotions": {
    "en": "Wheel of Emotions",
    "ro": ""
  }
};

export const useResourceTranslate = () => {
  const { lang } = useLang();
  return (label: keyof typeof MentorResourceLang | keyof typeof MenteeResourceLang) => {
    if (lang === "en") {
      return label;
    }
    if (MentorResourceLang[label as keyof typeof MentorResourceLang]) {
      return (MentorResourceLang as any)[label][lang] || label;
    }
    if (MenteeResourceLang[label as keyof typeof MenteeResourceLang]) {
      return (MenteeResourceLang as any)[label][lang] || label;
    }

    return label;
  }
};
