import React, { useEffect } from 'react'
import Fa from './FontAwesome'
import { Link, useLocation } from 'react-router-dom'
import { useUser } from '../hooks/useUser';
import { useQuery } from '@tanstack/react-query';
import sql from '../common/sql';
import useMessages from '../hooks/useMessages';
import { useWebSocket } from '../hooks/useWebSocket';
import { Labels } from '../common/lang';
import Lang from './Lang';
import { UserRole } from '../common/user';

const Footer = () => {
  const { user } = useUser();
  const { messagesCount: unreadChatMessages } = useMessages();
  const socket = useWebSocket();
  const unreadFeedbackQuery = useQuery({
    queryKey: ['unreadFeedbackQuery', user.uuid],
    queryFn: () => sql.unreadFeedbackQuery({ uuid: user.uuid }) as Promise<Array<{ Unread: number, Count: number }>>,
    enabled: !!user.uuid,
  });
  const feedbackUnread = unreadFeedbackQuery.data?.[0]?.Unread;

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      const [action] = event.data.split(':');
      console.log('onMessage', action);
      if (action === 'feedbackCountChanged') {
        console.log('feedbackCountChanged');
        unreadFeedbackQuery.refetch();
      }
    };
    socket.addEventListener('message', onMessage);
    return () => socket.removeEventListener('message', onMessage);
  }, [socket]);

  if (!user.email) return null;

  return (
    <footer>
      <nav className="navbar fixed-bottom">
        <div className="container">
          <div className="d-flex justify-content-around w-100 pt-1">
            <NavLink icon="home" label="Acasă" link="/home" />
            {user.role === "Coordonator" ?
              <NavLink icon="paper-plane" label="Observație" link="/feedback" unreadCount={feedbackUnread} /> :
              <NavLink icon="circle-dashed" label="Inel dispoziție" link="/mood-ring" />
            }
            <NavLink icon="message-captions" label="Discuție" link="/chat" unreadCount={unreadChatMessages} />
            <NavLink icon="screwdriver-wrench" label="Setări" link="/account" unreadCount={(["Mentorat", "Mentor"] as UserRole[]).includes(user.role) ? feedbackUnread : 0} />
          </div>
        </div>
      </nav>
    </footer>
  )
}

export default Footer

const NavLink = ({ icon = "", label = "" as Labels, link = "/", unreadCount = 0 }) => {
  const { pathname } = useLocation();
  const activeClass = pathname.startsWith(link) && "text-primary";
  return (
    <div className="text-center position-relative">
      <Link className={`nav-link pt-2 ${activeClass}`} to={link}>
        <Fa duotone={`${icon} fa-2xl`} />
        <small className='d-flex pt-2'><Lang text={label} /></small>
        {unreadCount > 0 && <span className="badge bg-danger rounded-pill position-absolute top-0 start-50 translate-middle">{unreadCount}</span>}
      </Link>
    </div>
  )
}