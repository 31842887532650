export interface MoodState {
    label: string;
    options: string[];
  }
  
  export const moodStates: MoodState[] = [
    {
        label: 'Treat yourself well',
        options: [
          'I’m really tough on myself, especially when things go wrong.',
          'I sometimes beat myself up over difficulties.',
          'I’m a mix – sometimes I’m self-critical, other times I’m okay with myself.',
          'I’m generally supportive and understanding towards myself when times get tough.',
          'No matter what happens, I always treat myself with kindness and respect.'
        ]
      },
      {
        label: 'Feel connected',
        options: [
          'I feel totally alone, like I have no one to turn to.',
          'It often feels like I don’t have many people in my corner.',
          'Sometimes I feel supported, other times I feel alone.',
          'I usually feel like I have support from friends or family.',
          'I always know I have people who back me up and care about me.'
        ]
      },
      {
        label: 'Stay present',
        options: [
          'I’m always overwhelmed by my thoughts and feelings.',
          'It’s hard for me to keep my mind on what’s happening right now.',
          'I manage to stay present sometimes, but often lose track.',
          'I can usually focus on the present without getting sidetracked by my thoughts.',
          'I’m really good at keeping focused and not letting my thoughts take over.'
        ]
      },
      {
        label: 'Feel up or down',
        options: [
          'Most days I feel down and don’t have the energy I need.',
          'I often struggle to feel good and motivated.',
          'My days are a mix – sometimes up, sometimes down.',
          'I generally feel positive and energetic.',
          'I feel awesome and ready every day.'
        ]
      },
      {
        label: 'Get involved',
        options: [
          'I don’t feel like doing much; I just keep to myself.',
          'I often hold back from getting involved in things.',
          'I get involved sometimes, but not consistently.',
          'I’m pretty engaged in what I do daily.',
          'I’m all in, every day, no matter what I’m doing.'
        ]
      },
      {
        label: 'Manage behavior',
        options: [
          'I often find myself in trouble or acting out.',
          'Under stress, I sometimes act in ways I later regret.',
          'My behavior is hit or miss, depending on the situation.',
          'I generally handle things calmly and keep myself cool.',
          'I always manage to act responsibly and make good choices, even when it’s tough.'
        ]
      }
    
    ];
  
  