import React from 'react'
import CalendarPicker from './CalendarPicker'
import Title from '../Title';
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import { MentoringEntity, MentoringSessionEntity, UserEntity } from '../../../../backend/src/shared/entity';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../Loading/Loading';
import FaRole from '../Icons/FaRole';
import MentoringSessionPrevious from './MentoringSessionPrevious';
import { useTranslate } from '../../common/lang';
import MentoringSessionProgress from './MentoringSessionProgress';
import Button from '../Button';
import ErrorBox from '../ErrorBox';
import { useUser } from '../../hooks/useUser';
import MentoringSessionNext from './MentoringSessionNext';
import MentoringSessionDateLocationForm from './MentoringSessionDateLocationForm';

const MentoringSessionPrevNextMeeting = () => {
    const { user } = useUser();
    const { idMentoring, date } = useParams();
    const tomorow = new Date();
    tomorow.setDate(tomorow.getDate() + 1);
    tomorow.setHours(10, 0, 0, 0);
    const [model, setModel] = React.useState<MentoringSessionEntity>({ IdMentoring: +idMentoring!, DateProposal: new Date(date ? `${date} 10:00:00` : tomorow) } as MentoringSessionEntity);
    const [saving, setSaving] = React.useState(false);
    const navigate = useNavigate();
    const translate = useTranslate();
    const [changeRequest, setChangeRequest] = React.useState(false);
    const mentoring = useQuery({
        queryKey: ['mentoringDetailsByIdMentoringQuery', idMentoring],
        queryFn: () => sql.mentoringDetailsByIdMentoringQuery({ idMentoring }) as Promise<[UserEntity[], MentoringEntity[], MentoringSessionEntity[]]>,
        enabled: !!user.account?.Id
    });

    const descByDateProposal = (a: MentoringSessionEntity, b: MentoringSessionEntity) => new Date(b.DateProposal).getTime() - new Date(a.DateProposal).getTime();

    if (mentoring.isLoading || saving) return <Loading />
    if (!mentoring.data) return <ErrorBox >{mentoring.error}</ErrorBox>
    const atendee = mentoring.data[0].find(u => u.Role !== user.account?.Role);
    const prevSession = mentoring.data[2].sort(descByDateProposal).find(s => new Date(s.DateProposal) < new Date());
    const nextSession = mentoring.data[2].sort(descByDateProposal).find(s => new Date(s.DateProposal) >= new Date());
    const minDate = new Date();
    minDate.setDate(minDate.getDate() + 1); // minDate is tomorow
    const maxDate = new Date(mentoring.data[1][0]?.DataStop || new Date());
    if (nextSession && nextSession.Id && !model.Id && !mentoring.isRefetching) setModel({ ...nextSession, DateProposal: date ? new Date(`${date} 10:00:00`) : nextSession.DateProposal });
    const handleAcceptProposal = () => {
        setSaving(true);
        sql.saveEntity('dbo.MentoringSession', { ...model, MentorAccepted: new Date() } as MentoringSessionEntity)
            .then(() => {
                if (date) setTimeout(() => navigate('/calendar'));
                else return mentoring.refetch();
            })
            .then(() => setModel({} as MentoringSessionEntity))
            .finally(() => setSaving(false));
    }
    const handleSave = () => {
        console.log('saving ... ', model);
        setSaving(true);
        sql.saveEntity('dbo.MentoringSession', { ...model, MentorAccepted: new Date(), MenteeAccepted: null } as MentoringSessionEntity)
            .then(() => date ? navigate('/calendar') : navigate(-1))
            .catch(() => setSaving(false));
    }
    const handleCancel = () => {
        setChangeRequest(false);
        if (nextSession) setModel({ ...nextSession });
    }
    return (
        <div className='row'>
            <Title text='Planificare sesiune' />
            <div className="col-12 px-4">
                <p className='text-muted fs-5'> <FaRole role='Mentorat' /> {atendee?.Name} </p>
                <MentoringSessionProgress mentoring={mentoring.data[1][0]} sessions={mentoring.data[2]} />
                <MentoringSessionPrevious session={prevSession} />
                <div className="alert alert-warning" role="alert">
                    <MentoringSessionNext nextSession={nextSession} />
                    {(changeRequest || !model.Id) &&
                        <>
                            <hr />
                            <MentoringSessionDateLocationForm model={model} minDate={minDate} maxDate={maxDate} setModel={setModel} handleSave={handleSave} handleCancel={handleCancel} />
                        </>
                    }

                </div>
                {changeRequest || !!model.Id &&
                    <>
                        <MentorAcceptProposal model={model} handleAcceptProposal={handleAcceptProposal} />
                        <Button color='btn-link' onClick={e => [e.preventDefault(), setChangeRequest(true)]}>{translate("Solicit modificare")}</Button>
                    </>
                }
            </div>
        </div>
    )
}

export default MentoringSessionPrevNextMeeting
const MentorAcceptProposal = ({ model = {} as MentoringSessionEntity, handleAcceptProposal = () => { } }) => {
    const translate = useTranslate();
    console.log('model', model);
    if (!model.Id || model.MentorAccepted) return null;

    return (
        <Button color='btn-link' onClick={e => [e.preventDefault(), handleAcceptProposal()]}>{translate("Acceptă data propusă")}</Button>
    );
}


