export type ApplicationLanguages = "ro" | "en" | "fr" | "it" | "pt" | "el" | "nl";
export const labels = {
    "Acasă": {
        en: "Home",
        fr: "Accueil",
        it: "Home",
        pt: "Início",
        el: "Αρχική",
        nl: "Startpagina"
    },
    "Discuție": {
        en: "Chat",
        fr: "Discussion",
        it: "Chat",
        pt: "Conversa",
        el: "Συζήτηση",
        nl: "Chat"
    },
    "Inel dispoziție": {
        en: "Mood Ring",
        fr: "Bague d'humeur",
        it: "Anello dell'umore",
        pt: "Anel de humor",
        el: "Δαχτυλίδι διάθεσης",
        nl: "Stemmingsring"
    },
    "Observație": {
        en: "Feedback",
        fr: "Commentaires",
        it: "Feedback",
        pt: "Comentários",
        el: "Σχόλια",
        nl: "Feedback"
    },
    "Setări": {
        en: "Settings",
        fr: "Paramètres",
        it: "Impostazioni",
        pt: "Configurações",
        el: "Ρυθμίσεις",
        nl: "Instellingen"
    },
    "Despre": {
        en: "About",
        fr: "À propos",
        it: "Informazioni",
        pt: "Sobre",
        el: "Σχετικά",
        nl: "Over"
    },
    "Mentorare": {
        en: "Mentoring",
        fr: "Mentorat",
        it: "Mentoring",
        pt: "Mentoria",
        el: "Καθοδήγηση",
        nl: "Mentorschap"
    },
    "Sesiuni": {
        en: "Sessions",
        fr: "Sessions",
        it: "Sessioni",
        pt: "Sessões",
        el: "Συνεδρίες",
        nl: "Sessies"
    },
    "Resurse": {
        en: "Resources",
        fr: "Ressources",
        it: "Risorse",
        pt: "Recursos",
        el: "Πόροι",
        nl: "Middelen"
    },
    "Calendar": {
        en: "Calendar",
        fr: "Calendrier",
        it: "Calendario",
        pt: "Calendário",
        el: "Ημερολόγιο",
        nl: "Kalender"
    },
    "Ghid utilizare aplicație": {
        en: "App User Guidelines",
        fr: "Guide de l'utilisateur de l'application",
        it: "Linee guida per l'utente dell'app",
        pt: "Diretrizes do usuário do aplicativo",
        el: "Οδηγίες χρήσης της εφαρμογής",
        nl: "App-gebruikersrichtlijnen"
    },
    "EU Funding": {
        en: "EU Funding",
        fr: "Financement de l'UE",
        it: "Finanziamento dell'UE",
        pt: "Financiamento da UE",
        el: "Χρηματοδότηση της ΕΕ",
        nl: "EU-financiering"
    },
    "Ce înseamnă mentorarea?": {
        en: "What is Mentoring?",
        fr: "Qu'est-ce que le mentorat?",
        it: "Cos'è il Mentoring?",
        pt: "O que é Mentoria?",
        el: "Τι είναι η καθοδήγηση;",
        nl: "Wat is mentorschap?"
    },
    "Politica de Protecție a Copilului": {
        en: "Our Child Protection Policy",
        fr: "Notre politique de protection de l'enfance",
        it: "La nostra politica di protezione dei bambini",
        pt: "Nossa Política de Proteção à Criança",
        el: "Η Πολιτική Προστασίας Παιδιών μας",
        nl: "Ons kinderbeschermingsbeleid"
    },
    "Parteneriate": {
        en: "Partnerships",
        fr: "Partenariats",
        it: "Partnership",
        pt: "Parcerias",
        el: "Συνεργασίες",
        nl: "Partnerschappen"
    },
    "Finanțare UE": {
        en: "EU Funding",
        fr: "Financement de l'UE",
        it: "Finanziamento dell'UE",
        pt: "Financiamento da UE",
        el: "Χρηματοδότηση της ΕΕ",
        nl: "EU-financiering"
    },
    "Contactează-ne": {
        en: "Contact Us",
        fr: "Contactez-nous",
        it: "Contattaci",
        pt: "Contate-Nos",
        el: "Επικοινωνήστε μαζί μας",
        nl: "Neem contact op"
    },
    "Setări cont": {
        en: "Account Settings",
        fr: "Paramètres du compte",
        it: "Impostazioni account",
        pt: "Configurações da conta",
        el: "Ρυθμίσεις λογαριασμού",
        nl: "Accountinstellingen"
    },
    "Schimbă parola": {
        en: "Change Password",
        fr: "Changer le mot de passe",
        it: "Cambia la password",
        pt: "Mudar senha",
        el: "Αλλαγή κωδικού πρόσβασης",
        nl: "Wachtwoord wijzigen"
    },
    "Salvează": {
        en: "Save",
        fr: "Enregistrer",
        it: "Salva",
        pt: "Salvar",
        el: "Αποθήκευση",
        nl: "Opslaan"
    },
    "Atașează fișiere": {
        en: "Attach Files",
        fr: "Joindre des fichiers",
        it: "Allega file",
        pt: "Anexar arquivos",
        el: "Επισύναψη αρχείων",
        nl: "Bestanden toevoegen"
    },
    "Ceva nu a mers bine": {
        en: "Something went wrong",
        fr: "Quelque chose s'est mal passé",
        it: "Qualcosa è andato storto",
        pt: "Algo deu errado",
        el: "Κάτι πήγε στραβά",
        nl: "Er is iets misgegaan"
    },
    "întâlnire online": {
        en: "online meeting",
        fr: "réunion en ligne",
        it: "incontro online",
        pt: "reunião online",
        el: "διαδικτυακή συνάντηση",
        nl: "online vergadering"
    },
    "Link-ul sesiunii online": {
        en: "Online meeting link",
        fr: "Lien de réunion en ligne",
        it: "Link alla riunione online",
        pt: "Link da reunião online",
        el: "Σύνδεσμος διαδικτυακής συνάντησης",
        nl: "Link naar online vergadering"
    },
    "întâlnire față în față": {
        en: "face-to-face meeting",
        fr: "réunion en face à face",
        it: "incontro faccia a faccia",
        pt: "reunião presencial",
        el: "συνάντηση πρόσωπο με πρόσωπο",
        nl: "persoonlijke vergadering"
    },
    'Popune selecția ca dată de întâlnire': {
        en: 'Propose Selection for Meeting',
        fr: 'Proposer la sélection pour la réunion',
        it: 'Proporre selezione per la riunione',
        pt: 'Propor seleção para reunião',
        el: 'Προτείνετε επιλογή για συνάντηση',
        nl: 'Selectie voorstellen voor vergadering'
    },
    "Adresa fizică": {
        en: "Physical address",
        fr: "Adresse physique",
        it: "Indirizzo fisico",
        pt: "Endereço físico",
        el: "Φυσική διεύθυνση",
        nl: "Fysiek adres"
    },
    "Acceptă data propusă": {
        en: "Accept the proposed date",
        fr: "Accepter la date proposée",
        it: "Accetta la data proposta",
        pt: "Aceitar a data proposta",
        el: "Αποδοχή της προτεινόμενης ημερομηνίας",
        nl: "Accepteer de voorgestelde datum"
    },
    "Solicit modificare": {
        en: "Request Change",
        fr: "Demander une modification",
        it: "Richiedi modifica",
        pt: "Solicitar alteração",
        el: "Αίτημα αλλαγής",
        nl: "Verzoek om wijziging"
    },
    "Anulează": {
        en: "Cancel",
        fr: "Annuler",
        it: "Annulla",
        pt: "Cancelar",
        el: "Ακύρωση",
        nl: "Annuleren"
    },
    "Gestiune echipe mentorare": {
        en: "Mentoring Teams Management",
        fr: "Gestion des équipes de mentorat",
        it: "Gestione dei team di mentoring",
        pt: "Gestão de equipes de mentoria",
        el: "Διαχείριση ομάδων καθοδήγησης",
        nl: "Beheer van mentorteams"
    },
    'Mentorare în curs': {
        en: 'Ongoing Mentoring',
        fr: 'Mentorat en cours',
        it: 'Mentoring in corso',
        pt: 'Mentoria em andamento',
        el: 'Σε εξέλιξη καθοδήγηση',
        nl: 'Lopend mentorschap'
    },
    'Persoane nementorate': {
        en: 'Unmentored persons',
        fr: 'Personnes non mentorées',
        it: 'Persone non mentore',
        pt: 'Pessoas não mentoradas',
        el: 'Μη καθοδηγούμενα άτομα',
        nl: 'Niet-mentored personen'
    },
    "Sesiuni de mentorare": {
        en: "Mentoring Sessions",
        fr: "Sessions de mentorat",
        it: "Sessioni di mentoring",
        pt: "Sessões de mentoria",
        el: "Συνεδρίες καθοδήγησης",
        nl: "Mentorschapsessies"
    },
    "Ultima ta întâlnire a fost pe": {
        en: "Your last meeting was on",
        fr: "Votre dernière réunion a eu lieu le",
        it: "Il tuo ultimo incontro è stato il",
        pt: "Sua última reunião foi em",
        el: "Η τελευταία σας συνάντηση ήταν στις",
        nl: "Je laatste vergadering was op"
    },
    "Următoarea sesiune va fi pe": {
        en: "Next meeting will be on",
        fr: "La prochaine réunion aura lieu le",
        it: "Il prossimo incontro sarà il",
        pt: "A próxima reunião será em",
        el: "Η επόμενη συνάντηση θα είναι στις",
        nl: "De volgende vergadering is op"
    },
    "Data și locul": {
        en: "Date and location",
        fr: "Date et lieu",
        it: "Data e luogo",
        pt: "Data e local",
        el: "Ημερομηνία και τοποθεσία",
        nl: "Datum en locatie"
    },
    "Acord de mentorat": {
        en: "Mentoring Agreement",
        fr: "Accord de mentorat",
        it: "Accordo di mentoring",
        pt: "Acordo de mentoria",
        el: "Συμφωνία καθοδήγησης",
        nl: "Mentorschapsafspraak"
    },
    "Editare relație de mentorat": {
        en: "Edit Mentoring Relationship",
        fr: "Modifier la relation de mentorat",
        it: "Modifica relazione di mentoring",
        pt: "Editar relacionamento de mentoria",
        el: "Επεξεργασία σχέσης καθοδήγησης",
        nl: "Mentorschapsrelatie bewerken"
    },
    "Adăugare relație de mentorat": {
        en: "Add Mentoring Relationship",
        fr: "Ajouter une relation de mentorat",
        it: "Aggiungi relazione di mentoring",
        pt: "Adicionar relacionamento de mentoria",
        el: "Προσθήκη σχέσης καθοδήγησης",
        nl: "Mentorschapsrelatie toevoegen"
    },
    "Șterge relația de mentorare": {
        en: "Delete Mentoring Relationship",
        fr: "Supprimer la relation de mentorat",
        it: "Elimina relazione di mentoring",
        pt: "Excluir relacionamento de mentoria",
        el: "Διαγραφή σχέσης καθοδήγησης",
        nl: "Mentorschapsrelatie verwijderen"
    },
    "Doriți să ștergeți relația.": {
        en: "Do you want to delete the relationship?",
        fr: "Voulez-vous supprimer la relation?",
        it: "Vuoi eliminare la relazione?",
        pt: "Deseja excluir o relacionamento?",
        el: "Θέλετε να διαγράψετε τη σχέση;",
        nl: "Wil je de relatie verwijderen?"
    },
    "Număr de ședințe": {
        en: "Number of Sessions",
        fr: "Nombre de sessions",
        it: "Numero di sessioni",
        pt: "Número de sessões",
        el: "Αριθμός συνεδριών",
        nl: "Aantal sessies"
    },
    "Data începerii": {
        en: "Start Date",
        fr: "Date de début",
        it: "Data di inizio",
        pt: "Data de início",
        el: "Ημερομηνία έναρξης",
        nl: "Startdatum"
    },
    "Data finalizării": {
        en: "End Date",
        fr: "Date de fin",
        it: "Data di fine",
        pt: "Data de término",
        el: "Ημερομηνία λήξης",
        nl: "Einddatum"
    },
    "Vizualizare observație": {
        en: "View Feedback",
        fr: "Voir les commentaires",
        it: "Visualizza feedback",
        pt: "Ver Feedback",
        el: "Προβολή σχολίων",
        nl: "Feedback bekijken"
    },
    "Trimite observație": {
        en: "Provide Feedback",
        fr: "Fournir des commentaires",
        it: "Fornire feedback",
        pt: "Fornecer Feedback",
        el: "Παροχή σχολίων",
        nl: "Feedback geven"
    },
    "Trimite": {
        en: "Send",
        fr: "Envoyer",
        it: "Invia",
        pt: "Enviar",
        el: "Αποστολή",
        nl: "Verzenden"
    },
    "Validare coordonatori": {
        en: "Coordinator Approval",
        fr: "Approbation du coordinateur",
        it: "Approvazione del coordinatore",
        pt: "Aprovação do coordenador",
        el: "Έγκριση συντονιστή",
        nl: "Goedkeuring coördinator"
    },
    "Pagina anterioară": {
        en: "Previous Page",
        fr: "Page précédente",
        it: "Pagina precedente",
        pt: "Página anterior",
        el: "Προηγούμενη σελίδα",
        nl: "Vorige pagina"
    },
    "Mentoring pentru a implica, a conecta și <br />a face puternici tinerii, <span class='text-primary'> VIITORUL NOSTRU</span>!": {
        en: "Mentoring to engage, connect and <br />empower young people, <span class='text-primary'> OUR FUTURE</span>!",
        fr: "Mentorat pour engager, connecter et <br />responsabiliser les jeunes, <span class='text-primary'> NOTRE AVENIR</span>!",
        it: "Mentoring per coinvolgere, connettere e <br />responsabilizzare i giovani, <span class='text-primary'> IL NOSTRO FUTURO</span>!",
        pt: "Mentoria para engajar, conectar e <br />capacitar os jovens, <span class='text-primary'> NOSSO FUTURO</span>!",
        el: "Καθοδήγηση για να εμπλέξει, να συνδέσει και <br />να ενδυναμώσει τους νέους, <span class='text-primary'> ΤΟ ΜΕΛΛΟΝ ΜΑΣ</span>!",
        nl: "Mentorschap om jongeren te betrekken, verbinden en <br />te versterken, <span class='text-primary'> ONZE TOEKOMST</span>!"
    },
    "— Selectează —": {
        en: "— Select —",
        fr: "— Sélectionner —",
        it: "— Seleziona —",
        pt: "— Selecionar —",
        el: "— Επιλέξτε —",
        nl: "— Selecteren —"
    },
    "— Selecție sesiune (opțional) —": {
        en: "— Select Session (optional) —",
        fr: "— Sélectionner une session (optionnel) —",
        it: "— Seleziona sessione (opzionale) —",
        pt: "— Selecionar sessão (opcional) —",
        el: "— Επιλέξτε συνεδρία (προαιρετικό) —",
        nl: "— Selecteer sessie (optioneel) —"
    },
    "— Selecție locație —": {
        en: "— Select Location —",
        fr: "— Sélectionner un lieu —",
        it: "— Seleziona luogo —",
        pt: "— Selecionar local —",
        el: "— Επιλέξτε τοποθεσία —",
        nl: "— Selecteer locatie —"
    },
    "— Selecție rol —": {
        en: "— Select Your Role —",
        fr: "— Sélectionner votre rôle —",
        it: "— Seleziona il tuo ruolo —",
        pt: "— Selecionar seu papel —",
        el: "— Επιλέξτε το ρόλο σας —",
        nl: "— Selecteer uw rol —"
    },
    "— Selecție țară —": {
        en: "— Select Your Country —",
        fr: "— Sélectionner votre pays —",
        it: "— Seleziona il tuo paese —",
        pt: "— Selecionar seu país —",
        el: "— Επιλέξτε τη χώρα σας —",
        nl: "— Selecteer uw land —"
    },
    "- Selecție organizație (opțional) -": {
        en: "— Select Organization (optional) —",
        fr: "— Sélectionner une organisation (optionnel) —",
        it: "— Seleziona organizzazione (opzionale) —",
        pt: "— Selecionar organização (opcional) —",
        el: "— Επιλέξτε οργανισμό (προαιρετικό) —",
        nl: "— Selecteer organisatie (optioneel) —"
    },
    "Admin": {
        en: "Admin",
        fr: "Admin",
        it: "Admin",
        pt: "Admin",
        el: "Admin",
        nl: "Admin"
    },
    "Coordonator": {
        en: "Coordinator",
        fr: "Coordinateur",
        it: "Coordinatore",
        pt: "Coordenador",
        el: "Συντονιστής",
        nl: "Coördinator"
    },
    "Mentor": {
        en: "Mentor",
        fr: "Mentor",
        it: "Mentore",
        pt: "Mentor",
        el: "Μέντορας",
        nl: "Mentor"
    },
    "Mentorat": {
        en: "Mentee",
        fr: "Mentoré",
        it: "Mentorato",
        pt: "Mentorado",
        el: "Καθοδηγούμενος",
        nl: "Mentorade"
    },
    "Planificare sesiune": {
        en: "Session Planning",
        fr: "Planification de session",
        it: "Pianificazione della sessione",
        pt: "Planejamento de sessão",
        el: "Προγραμματισμός συνεδρίας",
        nl: "Sessieplanning"
    },
    "Utilizator sau Email": {
        en: "Username or Email",
        fr: "Nom d'utilisateur ou e-mail",
        it: "Nome utente o email",
        pt: "Nome de usuário ou email",
        el: "Όνομα χρήστη ή email",
        nl: "Gebruikersnaam of email"
    },
    "Parolă": {
        en: "Password",
        fr: "Mot de passe",
        it: "Password",
        pt: "Senha",
        el: "Κωδικός πρόσβασης",
        nl: "Wachtwoord"
    },
    "Confirmare Parolă": {
        en: "Confirm Password",
        fr: "Confirmer le mot de passe",
        it: "Conferma Password",
        pt: "Confirmar Senha",
        el: "Επιβεβαίωση Κωδικού",
        nl: "Bevestig Wachtwoord"
    },
    "Nume": {
        en: "First Name",
        fr: "Prénom",
        it: "Nome",
        pt: "Nome",
        el: "Όνομα",
        nl: "Voornaam"
    },
    "Prenume": {
        en: "Last Name",
        fr: "Nom",
        it: "Cognome",
        pt: "Sobrenome",
        el: "Επώνυμο",
        nl: "Achternaam"
    },
    "Email": {
        en: "Email",
        fr: "Email",
        it: "Email",
        pt: "Email",
        el: "Email",
        nl: "Email"
    },
    "Înregistrează-te": {
        en: "Sign Up",
        fr: "S'inscrire",
        it: "Iscriviti",
        pt: "Inscrever-se",
        el: "Εγγραφείτε",
        nl: "Aanmelden"
    },
    "Intră în cont": {
        en: "Login",
        fr: "Connexion",
        it: "Accedi",
        pt: "Entrar",
        el: "Σύνδεση",
        nl: "Inloggen"
    },
    "Link activare cont": {
        en: "Account activation link",
        fr: "Lien d'activation de compte",
        it: "Link di attivazione dell'account",
        pt: "Link de ativação da conta",
        el: "Σύνδεσμος ενεργοποίησης λογαριασμού",
        nl: "Account activatielink"
    },
    "Recuperare parolă": {
        en: "Password Recovery",
        fr: "Récupération de mot de passe",
        it: "Recupero Password",
        pt: "Recuperação de senha",
        el: "Ανάκτηση κωδικού πρόσβασης",
        nl: "Wachtwoordherstel"
    },
    "Adresa de email": {
        en: "Email Address",
        fr: "Adresse e-mail",
        it: "Indirizzo email",
        pt: "Endereço de email",
        el: "Διεύθυνση email",
        nl: "E-mailadres"
    },
    "Vei primi un email cu link pentru resetare parolă": {
        en: "You will receive an email with a password reset link",
        fr: "Vous recevrez un e-mail avec un lien de réinitialisation du mot de passe",
        it: "Riceverai un'email con il link per reimpostare la password",
        pt: "Você receberá um email com um link para redefinir a senha",
        el: "Θα λάβετε ένα email με έναν σύνδεσμο επαναφοράς κωδικού πρόσβασης",
        nl: "Je ontvangt een e-mail met een link om je wachtwoord te resetten"
    },
    "Trimite email": {
        en: "Send email",
        fr: "Envoyer un e-mail",
        it: "Invia email",
        pt: "Enviar email",
        el: "Αποστολή email",
        nl: "Email verzenden"
    },
    "Un email a fost trimis la @email. Verifică dacă ai primit email-ul în Inbox sau în folderul Spam (email nedorit)": {
        en: "An email has been sent to @email. Check if you received the email in the Inbox or in the Spam folder.",
        fr: "Un e-mail a été envoyé à @email. Vérifiez si vous avez reçu l'e-mail dans la boîte de réception ou dans le dossier spam.",
        it: "Un'email è stata inviata a @email. Controlla se hai ricevuto l'email nella casella di posta in arrivo o nella cartella spam.",
        pt: "Um email foi enviado para @email. Verifique se você recebeu o email na Caixa de Entrada ou na pasta de Spam.",
        el: "Ένα email στάλθηκε στο @email. Ελέγξτε αν λάβατε το email στα Εισερχόμενα ή στο φάκελο Spam.",
        nl: "Er is een e-mail verzonden naar @email. Controleer of je de e-mail in de Inbox of in de Spam-folder hebt ontvangen."
    },
    "Înapoi la conectare": {
        en: "Back to login",
        fr: "Retour à la connexion",
        it: "Torna al login",
        pt: "Voltar ao login",
        el: "Επιστροφή στη σύνδεση",
        nl: "Terug naar inloggen"
    },
    "<b>Crează o parolă</b> și <b>repetă parola</b> trebuie să fie identice": {
        en: "<b>Create a password</b> and <b>repeat the password</b> must be identical",
        fr: "<b>Créez un mot de passe</b> et <b>répétez le mot de passe</b> doivent être identiques",
        it: "<b>Crea una password</b> e <b>ripeti la password</b> devono essere identiche",
        pt: "<b>Crie uma senha</b> e <b>repita a senha</b> devem ser idênticas",
        el: "<b>Δημιουργήστε έναν κωδικό πρόσβασης</b> και <b>επαναλάβετε τον κωδικό πρόσβασης</b> πρέπει να είναι ίδιοι",
        nl: "<b>Maak een wachtwoord</b> en <b>herhaal het wachtwoord</b> moeten identiek zijn"
    },
    "Resetare parolă": {
        en: "Password Reset",
        fr: "Réinitialisation du mot de passe",
        it: "Reimpostazione della password",
        pt: "Redefinição de senha",
        el: "Επαναφορά κωδικού πρόσβασης",
        nl: "Wachtwoord resetten"
    },
    "Setează noua parolă": {
        en: "Set new password",
        fr: "Définir un nouveau mot de passe",
        it: "Imposta una nuova password",
        pt: "Definir nova senha",
        el: "Ορίστε νέο κωδικό πρόσβασης",
        nl: "Nieuw wachtwoord instellen"
    },
    "Cont creat cu succes !": {
        en: "Account created successfully!",
        fr: "Compte créé avec succès!",
        it: "Account creato con successo!",
        pt: "Conta criada com sucesso!",
        el: "Ο λογαριασμός δημιουργήθηκε με επιτυχία!",
        nl: "Account succesvol aangemaakt!"
    },
    "Vei putea să te conectezi după ce îți validezi adresa de email ! <br /> Verifică căsuța de email <b>@email</b> și folosește link-ul de activare primit. Verifică atât în folderul <b>inbox</b> cât și în folderul <b>spam</b>.": {
        en: "You will be able to log in after validating your email address! <br /> Check your email box <b>@email</b> and use the activation link received. Check both the <b>inbox</b> and <b>spam</b> folders.",
        fr: "Vous pourrez vous connecter après avoir validé votre adresse e-mail! <br /> Vérifiez votre boîte e-mail <b>@email</b> et utilisez le lien d'activation reçu. Vérifiez à la fois les dossiers <b>boîte de réception</b> et <b>spam</b>.",
        it: "Potrai accedere dopo aver convalidato il tuo indirizzo email! <br /> Controlla la tua casella di posta elettronica <b>@email</b> e utilizza il link di attivazione ricevuto. Controlla sia la cartella <b>posta in arrivo</b> che la cartella <b>spam</b>.",
        pt: "Você poderá fazer login após validar seu endereço de e-mail! <br /> Verifique sua caixa de e-mail <b>@email</b> e use o link de ativação recebido. Verifique as pastas <b>Caixa de Entrada</b> e <b>Spam</b>.",
        el: "Θα μπορείτε να συνδεθείτε αφού επικυρώσετε τη διεύθυνση email σας! <br /> Ελέγξτε το γραμματοκιβώτιο σας <b>@email</b> και χρησιμοποιήστε τον σύνδεσμο ενεργοποίησης που λάβατε. Ελέγξτε τόσο τους φακέλους <b>εισερχόμενα</b> όσο και τους φακέλους <b>ανεπιθύμητα</b>.",
        nl: "U kunt inloggen nadat u uw e-mailadres heeft gevalideerd! <br /> Controleer uw e-mailbox <b>@email</b> en gebruik de ontvangen activeringslink. Controleer zowel de <b>inbox</b> als de <b>spam</b> mappen."
    },
    "Te-ai înregistrat cu succes!": {
        en: "You have successfully registered!",
        fr: "Vous vous êtes inscrit avec succès!",
        it: "Ti sei registrato con successo!",
        pt: "Você se registrou com sucesso!",
        el: "Έχετε εγγραφεί με επιτυχία!",
        nl: "Je hebt je succesvol geregistreerd!"
    },
    'Contul <b>@email</b> este deja înregistrat. Nu mai știi parola ? Folosește opțiunea <a href="/password-recover" class="card-link">resetare parolă</a>.': {
        en: 'The <b>@email</b> account is already registered. Don\'t you remember the password? Use the <a href="/password-recover" class="card-link">password reset</a> option.',
        fr: 'Le compte <b>@email</b> est déjà enregistré. Vous ne vous souvenez plus du mot de passe? Utilisez l\'option <a href="/password-recover" class="card-link">réinitialisation du mot de passe</a>.',
        it: 'L\'account <b>@email</b> è già registrato. Non ricordi la password? Utilizza l\'opzione di <a href="/password-recover" class="card-link">reimpostazione della password</a>.',
        pt: 'A conta <b>@email</b> já está registrada. Você não se lembra da senha? Use a opção de <a href="/password-recover" class="card-link">redefinição de senha</a>.',
        el: 'Ο λογαριασμός <b>@email</b> είναι ήδη καταχωρημένος. Δεν θυμάστε τον κωδικό πρόσβασης; Χρησιμοποιήστε την επιλογή <a href="/password-recover" class="card-link">επαναφοράς κωδικού πρόσβασης</a>.',
        nl: 'Het account <b>@email</b> is al geregistreerd. Weet je het wachtwoord niet meer? Gebruik de optie voor <a href="/password-recover" class="card-link">wachtwoord reset</a>.'
    },
    'Contul nu a fost activat !<br/> Verifică căsuța de email ( <b>inbox și spam </b> ) pentru link-ul de activare.': {
        en: 'The account has not been activated! <br/> Check the email box ( <b>inbox and spam </b> ) for the activation link.',
        fr: 'Le compte n\'a pas été activé! <br/> Vérifiez la boîte de réception ( <b>inbox et spam </b> ) pour le lien d\'activation.',
        it: 'L\'account non è stato attivato! <br/> Controlla la casella di posta elettronica ( <b>posta in arrivo e spam </b> ) per il link di attivazione.',
        pt: 'A conta não foi ativada! <br/> Verifique a caixa de e-mail ( <b>Caixa de entrada e Spam </b> ) para o link de ativação.',
        el: 'Ο λογαριασμός δεν έχει ενεργοποιηθεί! <br/> Ελέγξτε το γραμματοκιβώτιο ( <b>εισερχόμενα και ανεπιθύμητα </b> ) για τον σύνδεσμο ενεργοποίησης.',
        nl: 'Het account is niet geactiveerd! <br/> Controleer de e-mailbox ( <b>inbox en spam </b> ) voor de activeringslink.'
    },
    'Cont <b>temporar dezactivat</b>. Poți încerca din nou peste @timeout minute.': {
        en: 'Account <b>temporarily disabled</b>. You can try again in <b>@timeout</b> minutes.',
        fr: 'Compte <b>temporairement désactivé</b>. Vous pouvez réessayer dans <b>@timeout</b> minutes.',
        it: 'Account <b>temporaneamente disabilitato</b>. Puoi riprovare tra <b>@timeout</b> minuti.',
        pt: 'Conta <b>temporariamente desativada</b>. Você pode tentar novamente em <b>@timeout</b> minutos.',
        el: 'Ο λογαριασμός είναι <b>προσωρινά απενεργοποιημένος</b>. Μπορείτε να δοκιμάσετε ξανά σε <b>@timeout</b> λεπτά.',
        nl: 'Account <b>tijdelijk uitgeschakeld</b>. U kunt het over <b>@timeout</b> minuten opnieuw proberen.'
    },
    'Nu mai știi parola ? Accesează <a href="/password-recover" class="card-link">resetează parola</a>.': {
        en: 'Don\'t you remember the password? Access <a href="/password-recover" class="card-link">reset password</a>.',
        fr: 'Vous ne vous souvenez plus du mot de passe? Accédez à <a href="/password-recover" class="card-link">réinitialisation du mot de passe</a>.',
        it: 'Non ricordi la password? Accedi a <a href="/password-recover" class="card-link">reimposta password</a>.',
        pt: 'Você não se lembra da senha? Acesse <a href="/password-recover" class="card-link">redefinir senha</a>.',
        el: 'Δεν θυμάστε τον κωδικό πρόσβασης; Αποκτήστε πρόσβαση στην <a href="/password-recover" class="card-link">επαναφορά κωδικού πρόσβασης</a>.',
        nl: 'Weet je het wachtwoord niet meer? Ga naar <a href="/password-recover" class="card-link">wachtwoord resetten</a>.'
    },
    'Nu există cont asociat cu acest email !': {
        en: 'There is no account associated with this email!',
        fr: 'Il n\'y a aucun compte associé à cet e-mail!',
        it: 'Non c\'è nessun account associato a questa email!',
        pt: 'Não há nenhuma conta associada a este email!',
        el: 'Δεν υπάρχει λογαριασμός συνδεδεμένος με αυτό το email!',
        nl: 'Er is geen account gekoppeld aan dit e-mailadres!'
    },
    'Acest link a fost deja folosit!<hr/> Poți încerca să te <a href="/login"> autentifici </a> cu email și parolă, sau poți folosi opțiunea <a href="/password-recover">resetare parolă</a> dacă nu mai știi parola.</p>': {
        en: 'This link has already been used! <hr/> You can try to <a href="/login"> log in </a> with email and password, or you can use the <a href="/password-recover">reset password</a> option if you don\'t remember the password.</p>',
        fr: 'Ce lien a déjà été utilisé! <hr/> Vous pouvez essayer de <a href="/login"> vous connecter </a> avec e-mail et mot de passe, ou vous pouvez utiliser l\'option de <a href="/password-recover">réinitialisation du mot de passe</a> si vous ne vous souvenez pas du mot de passe.</p>',
        it: 'Questo link è già stato utilizzato! <hr/> Puoi provare a <a href="/login"> accedere </a> con email e password, oppure puoi utilizzare l\'opzione di <a href="/password-recover">reimpostazione della password</a> se non ricordi la password.</p>',
        pt: 'Este link já foi utilizado! <hr/> Você pode tentar <a href="/login"> fazer login </a> com email e senha, ou pode usar a opção de <a href="/password-recover">redefinição de senha</a> se não lembrar a senha.</p>',
        el: 'Αυτός ο σύνδεσμος έχει ήδη χρησιμοποιηθεί! <hr/> Μπορείτε να δοκιμάσετε να <a href="/login"> συνδεθείτε </a> με email και κωδικό πρόσβασης ή μπορείτε να χρησιμοποιήσετε την επιλογή <a href="/password-recover">επαναφοράς κωδικού πρόσβασης</a> εάν δεν θυμάστε τον κωδικό πρόσβασης.</p>',
        nl: 'Deze link is al gebruikt! <hr/> U kunt proberen om in te <a href="/login"> loggen </a> met e-mail en wachtwoord, of u kunt de optie voor <a href="/password-recover">wachtwoord resetten</a> gebruiken als u het wachtwoord niet meer weet.</p>'
    },
    "Deconectare": {
        en: "Logout",
        fr: "Déconnexion",
        it: "Disconnetti",
        pt: "Sair",
        el: "Αποσύνδεση",
        nl: "Uitloggen"
    },
    "Legat de sesiunea": {
        en: "Related to session",
        fr: "Lié à la session",
        it: "Relativo alla sessione",
        pt: "Relacionado à sessão",
        el: "Σχετικό με τη συνεδρία",
        nl: "Gerelateerd aan sessie"
    },
    "Ca răspuns la": {
        en: "In reply to",
        fr: "En réponse à",
        it: "In risposta a",
        pt: "Em resposta a",
        el: "Σε απάντηση του",
        nl: "In antwoord op"
    },
    "Răspunde": {
        en: "Reply",
        fr: "Répondre",
        it: "Rispondi",
        pt: "Responder",
        el: "Απάντηση",
        nl: "Beantwoorden"
    },
    "Subiect": {
        en: "Subject",
        fr: "Sujet",
        it: "Oggetto",
        pt: "Assunto",
        el: "Θέμα",
        nl: "Onderwerp"
    },
    "Trage fișierele aici sau folosește": {
        en: "Drag files here or use",
        fr: "Glissez les fichiers ici ou utilisez",
        it: "Trascina i file qui o usa",
        pt: "Arraste os arquivos aqui ou use",
        el: "Σύρετε τα αρχεία εδώ ή χρησιμοποιήστε",
        nl: "Sleep bestanden hierheen of gebruik"
    },
    "Închide": {
        en: "Close",
        fr: "Fermer",
        it: "Chiudi",
        pt: "Fechar",
        el: "Κλείσιμο",
        nl: "Sluiten"
    },
    "Momentan, site-ul Mentor+ se află în construcție, pentru a vă putea oferi o experiență îmbunătățită. Între timp, vă invităm să rămâneți informați în legătură cu ultimele noutăți ale proiectului prin intermediul rețelelor noastre de socializare: ": {
        en: "Currently, the Mentor+ website is under construction, in order to offer you an improved experience. In the meantime, we invite you to stay informed about the latest news of the project through our social networks: ",
        fr: "Actuellement, le site Mentor+ est en construction, afin de vous offrir une expérience améliorée. En attendant, nous vous invitons à rester informé des dernières nouvelles du projet via nos réseaux sociaux: ",
        it: "Attualmente, il sito Mentor+ è in costruzione, per offrirti un'esperienza migliorata. Nel frattempo, ti invitiamo a rimanere informato sulle ultime novità del progetto attraverso i nostri social network: ",
        pt: "Atualmente, o site do Mentor+ está em construção, para oferecer a você uma experiência aprimorada. Enquanto isso, convidamos você a se manter informado sobre as últimas novidades do projeto através de nossas redes sociais: ",
        el: "Αυτή τη στιγμή, ο ιστότοπος Mentor+ βρίσκεται υπό κατασκευή, προκειμένου να σας προσφέρουμε μια βελτιωμένη εμπειρία. Εν τω μεταξύ, σας προσκαλούμε να μείνετε ενημερωμένοι σχετικά με τα τελευταία νέα του έργου μέσω των κοινωνικών μας δικτύων: ",
        nl: "Momenteel is de Mentor+ website in aanbouw, om u een verbeterde ervaring te bieden. In de tussentijd nodigen we u uit om op de hoogte te blijven van het laatste nieuws over het project via onze sociale netwerken: "
    },
    "Vă mulțumim pentru înțelegere!": {
        en: "Thank you for your understanding!",
        fr: "Merci pour votre compréhension!",
        it: "Grazie per la comprensione!",
        pt: "Obrigado pela compreensão!",
        el: "Σας ευχαριστούμε για την κατανόηση!",
        nl: "Bedankt voor uw begrip!"
    },
    "Ne găsiți pe:": {
        en: "Find us on:",
        fr: "Retrouvez-nous sur:",
        it: "Ci trovi su:",
        pt: "Encontre-nos em:",
        el: "Μας βρίσκετε στο:",
        nl: "Vind ons op:"
    }

};
