import Form from "./Form";
import Motto from "./Motto";

const LoginContainer = ({ children, onSubmit }: any) => {
  return (
    <div className="row">
      <div className="col-12">
        <Motto showHeader />
        <Form onSubmit={onSubmit}>
          {children}
        </Form>
      </div>
    </div>
  );
};

export default LoginContainer;
