import React from 'react'
import Title from '../Title'

const ChildProtectionPolicy = () => {
    return (
        <div>
            <Title text='Politica de Protecție a Copilului' />
            <h6>1. Who is a Child? </h6>
            <p>
                As described in Article 1 of the Convention on the Rights of the Child (1989),  we consider anyone under the age of 18 a child. This means they have some specific rights alongside those enjoyed by other members of the society as they are in the process of development.
            </p>
            <p>
                Adults must respect their maturity and capacity to negotiate situations which vary depending on the phase of growth. They should be provided with a safe and protective environment in which they receive the care needed to develop physically, cognitively, and emotionally. Neglecting these needs is understood as a form of violence against children.
            </p>
            <h6>2. What is Child Abuse?</h6>
            <p>
                Child abuse is any form of physical, emotional or sexual abuse and includes exploitation of children, commercial or otherwise. Abandonment and neglect of a child which results in actual or potential damage against a child can also be considered an abuse. Abuse may be deliberate, or a result of a lack of preventative action and protection. We, as Mentor+ Partnership strongly condemn all forms of child abuse and exploitation of children.  In order to protect our children in all our activities, and to guarantee their respect and physical and emotional protection, the mentors are expected report any instance of child abuse to the coordinator directly.
            </p>
            <h6>3. Code of Conduct for Mentors</h6>
            <p>
                Mentor+ Partners highly esteem the principle that children and young people can benefit immeasurably from supportive relationships with adults who can guide and help them. To make this principle work seamlessly, the partnership considers the code of conduct outlined here below extremely important. Those should be digested with the fact that especially the mentors’ behaviour may be open to scrutiny and that they are expected to be models of best practice in relation to working with children and young people and not engage in any of the following behaviours:
            </p>
            <ul>
                <li>
                    Bullying, harassment, or discrimination against any child (even in subtle ways such as drawing attention to personal or physical differences).
                </li>
                <li>
                    Belittling or shaming a child who is struggling or who finds the work difficult.
                </li>
                <li>
                    Hitting, smacking, or causing physical harm to a child as an outlet for frustration or as a means of discipline.
                </li>
                <li>
                    Interacting with children in an inappropriate way, singling them out for praise or attention or trying to humiliate them or make them feel uncomfortable.
                </li>
                <li>
                    Entering an intimate or sexual relationship with a child or using sexual language around them including suggestive comments or conversations.
                </li>
                <li>
                    Entering a relationship with a mentee outside of the project.
                </li>
                <li>
                    Engaging one-to-one with the mentees on social media, such as WhatsApp, Snapchat, Instagram or another social media service, messaging service or game. Accepting friend invitations or sharing personal numbers with any of the children in the project.
                </li>
                <li>
                    Taking pictures of the children or sharing photos of yourself.
                </li>
                <li>
                    Giving gifts, privileges, or rewards to a child to build a special relationship with them.
                    Undertaking mentoring duties whilst under the influence of alcohol or drugs.
                </li>
            </ul>

        </div>
    )
}

export default ChildProtectionPolicy