import React from 'react'
import 'bootstrap/dist/js/bootstrap.min.js';
import Fa from './FontAwesome';
import { useLang } from '../hooks/useLang';
import { languages } from '../common/lang';
import { Link } from 'react-router-dom';

const LangPicker = () => {
    const { lang, setLang } = useLang();
    return (
        <div className="dropdown lang-picker">
            <Link data-bs-toggle="dropdown" aria-expanded="false" to="" onClick={e=>e.preventDefault()}>
                <Fa duotone="language fa-xl" />
            </Link>
            <ul className="dropdown-menu">
                {languages.map((language) => (
                    <li key={language} onClick={e => [e.preventDefault(), setLang(language)]}>
                        <Link className="dropdown-item" to="" onClick={e=>e.preventDefault()}>
                            <Fa regular={`${lang === language ? 'check' : ''} fa-fw`} />{language}
                        </Link></li>
                ))}
            </ul>
        </div>
    )
}

export default LangPicker