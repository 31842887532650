import { MenteeResourceLang, MentorResourceLang } from "./lang";

export const MentorResourceLink: { [key in keyof typeof MentorResourceLang]: any } = {
  "Affirmation Session": {
    "Dutch": {},
    "English": {
      "filename": "Affirmation Session.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Assessment Tool": {
    "Dutch": {},
    "English": {
      "filename": "Assessment Tool.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Breathing Sessions": {
    "Dutch": {},
    "English": {
      "filename": "Breathing Sessions.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Dealing with Conflicts and Difficult Behaviors": {
    "Dutch": {},
    "English": {
      "filename": "Dealing with Conflicts and Difficult Behaviors.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Engaging in Change Talk": {
    "Dutch": {},
    "English": {
      "filename": "Engaging in Change Talk.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Identifying Triggers": {
    "Dutch": {},
    "English": {
      "filename": "Identifying Triggers.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Mindful Communication": {
    "Dutch": {},
    "English": {
      "filename": "Mindful Communication.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Mock MI": {
    "Dutch": {},
    "English": {
      "filename": "Mock MI.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Reflection to Change Talk": {
    "Dutch": {},
    "English": {
      "filename": "Reflection to Change Talk.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Simple Reflection": {
    "Dutch": {},
    "English": {
      "filename": "Simple Reflection.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  }
};

export const MenteeResourceLink: { [key in keyof typeof MenteeResourceLang]: any } = {
  "ACT_ Recognizing Values and Ideas": {
    "Dutch": {},
    "English": {
      "filename": "ACT_Recognizing Values and Ideas.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Affirmation Session": {
    "Dutch": {},
    "English": {
      "filename": "Affirmation Session.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Assessment Tool": {
    "Dutch": {},
    "English": {
      "filename": "Assessment Tool.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Breathing Sessions": {
    "Dutch": {},
    "English": {
      "filename": "Breathing Sessions.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Building a CV": {
    "Dutch": {},
    "English": {
      "filename": "Building a CV.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Challenging Negative Thoughts Worksheet": {
    "Dutch": {},
    "English": {
      "filename": "Challenging Negative Thoughts Worksheet.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Changing the Jackal": {
    "Dutch": {},
    "English": {
      "filename": "Changing the Jackal.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Cognitive Restructuring Practice": {
    "Dutch": {},
    "English": {
      "filename": "Cognitive Restructuring Practice.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Coping Thoughts Worksheet": {
    "Dutch": {},
    "English": {
      "filename": "Coping Thoughts Worksheet.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Emotion Journal": {
    "Dutch": {},
    "English": {
      "filename": "Emotion Journal.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Empathy Map": {
    "Dutch": {},
    "English": {
      "filename": "Empathy Map.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "GROW": {
    "Dutch": {},
    "English": {
      "filename": "GROW.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Healthy_Unhealthy Relationships - Matching Activity": {
    "Dutch": {},
    "English": {
      "filename": "Healthy_Unhealthy Relationships - Matching Activity.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "How would you treat a friend": {
    "Dutch": {},
    "English": {
      "filename": "How Would You Treat a Friend.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Identifying Triggers": {
    "Dutch": {},
    "English": {
      "filename": "Identifying Triggers.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Letter to Future Me": {
    "Dutch": {},
    "English": {
      "filename": "Letter to Future Me.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "List of Coping Skills": {
    "Dutch": {},
    "English": {
      "filename": "List of Coping Skills.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Mindful Communication": {
    "Dutch": {},
    "English": {
      "filename": "Mindful Communication.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Mock Interview": {
    "Dutch": {},
    "English": {
      "filename": "Mock Interview.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "My Safe Place": {
    "Dutch": {},
    "English": {
      "filename": "My Safe Place.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Pick a Treat, Tell a Story": {
    "Dutch": {},
    "English": {
      "filename": "Pick a Treat, Tell a Story.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Plan of Action": {
    "Dutch": {},
    "English": {
      "filename": "Plan of Action.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Positive Pathways Collage": {
    "Dutch": {},
    "English": {
      "filename": "Positive Pathways Collage.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Prevent from Boiling Over": {
    "Dutch": {},
    "English": {
      "filename": "Prevent from Boiling Over.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Respect": {
    "Dutch": {},
    "English": {
      "filename": "Respect.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Stinky Fish": {
    "Dutch": {},
    "English": {
      "filename": "Stinky Fish.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "The 5-4-3-2-1 Emotional Regulation Technique": {
    "Dutch": {},
    "English": {
      "filename": "The 5-4-3-2-1 Emotional Regulation Technique.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "The Detective": {
    "Dutch": {},
    "English": {
      "filename": "The Detective.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "The Endless Cycle": {
    "Dutch": {},
    "English": {
      "filename": "The Endless Cycle.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "The Jackal": {
    "Dutch": {},
    "English": {
      "filename": "The Jackal.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "The Power of a Story": {
    "Dutch": {},
    "English": {
      "filename": "The Power of a Story.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Thoughts Matter": {
    "Dutch": {},
    "English": {
      "filename": "Thoughts Matter.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Through the Scanner": {
    "Dutch": {},
    "English": {
      "filename": "Through the Scanner.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Tree of Life": {
    "Dutch": {},
    "English": {
      "filename": "Tree of Life.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Unhealthy Relationships - Quiz": {
    "Dutch": {},
    "English": {
      "filename": "Unhealthy Relationships - Quiz.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Values": {
    "Dutch": {},
    "English": {
      "filename": "Values.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "What to do in times of stress": {
    "Dutch": {},
    "English": {
      "filename": "What to do in times of stress.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  },
  "Wheel of Emotions": {
    "Dutch": {},
    "English": {
      "filename": "The Wheel of Emotions.pdf"
    },
    "French": {},
    "Greek": {},
    "Italian": {},
    "Portuguese": {},
    "Romanian": {}
  }
};