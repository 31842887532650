import React from 'react'
import Title from '../Title'

const EuFunding = () => {
    return (
        <div>
            <Title text="EU Funding" />
            <p>
                Mentor+ Project has received funding from the European Union’s Erasmus+ Programme under agreement number no: 2021-2-BE05-KA220-YOU-000050037.
            </p>
            <p>
                The European Commission support for the production of this mobile application and the publications therein does not constitute an endorsement of the contents which reflect the views only of the author(s), and the Commission cannot be held responsible for any use which may be made of the information contained therein.
            </p>
            <p>
                <img src="/images/eu-funding.png" className='img-fluid' alt="" />
            </p>
        </div>
    )
}

export default EuFunding