import React from 'react'
import { useUser } from '../../hooks/useUser';
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import { ChatEntity, UserEntity } from '../../../../backend/src/shared/entity';
import Loading from '../Loading/Loading';
import ErrorBox from '../ErrorBox';
import Fa from '../FontAwesome';
import { Link } from 'react-router-dom';
import ChatMessage from './ChatMessage';
import { useWebSocket } from '../../hooks/useWebSocket';

const ChatRoom = ({ Uuid, Avatar, Name }: Pick<UserEntity, "Avatar" | "Uuid" | "Name">) => {
    const { user } = useUser();
    const socket = useWebSocket();
    const [message, setMessage] = React.useState('');
    const [messages, setMessages] = React.useState<Array<ChatEntity>>([]);
    const { isLoading, isRefetching, data, error } = useQuery({
        queryKey: ['chat', Uuid, user.uuid],
        queryFn: () => sql.messagesForChatQuery({ loginUuid: user.uuid, otherUuid: Uuid }) as Promise<Array<ChatEntity>>,
        enabled: !!Uuid,
    });
    socket.onmessage = (event) => {
        const [from, message] = event.data.split(':');
        // console.log({ from, message });
        if (from === Uuid) {
            setMessages([...messages, {
                Id: Math.random() * 1000000,
                From: from,
                To: user.uuid,
                Message: message
            } as ChatEntity]);
        }
    }

    if (isRefetching && messages.length) setTimeout(() => setMessages([]));
    if (isLoading || isRefetching) return <Loading />;
    if (error) return <ErrorBox>{error}</ErrorBox>;
    if (!data) return <ErrorBox>No data</ErrorBox>;
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 100);
    const hadleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (message) {
                socket.send(`${Uuid}:${message}`);
                setMessage('');
                setMessages([...messages, {
                    Id: Math.random() * 1000000,
                    From: user.uuid,
                    To: Uuid,
                    Message: message
                } as ChatEntity]);
            }
            (document.querySelector('input[type="text"]') as HTMLInputElement)?.focus();
        }
    }

    return (
        <>
            <div>
                <p className='d-flex justify-content-between'>
                    <Link to={`/chat`}>
                        <Fa light="caret-left fa-xl" />
                    </Link>
                    <b>{Name}</b>
                    <Link to={`/chat/${Uuid}`}>
                        <Fa solid="dots fa-xl" />
                    </Link>
                </p>
            </div>
            <div className='chat-room-container'>
                {(data.concat(messages)).map((msg) => <ChatMessage key={msg.Id} {...msg} avatar={msg.From === Uuid ? Avatar : (user.account?.Avatar)} />)}
            </div>
            <nav className="navbar fixed-bottom bg-light px-4">
                <input type="text" className='form-control pe-5'
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    onKeyUp={hadleKeyUp} />
                {/* <Fa duotone='paper-plane-top fa-lg fa-rotate-by' style="--fa-rotate-angle: 45deg;" onClick={() => hadleKeyUp({ key: 'Enter' } as any)} /> */}
                <i className='fa-regular fa-arrow-right-from-arc fa-xl fa-rotate-by' onClick={() => hadleKeyUp({ key: 'Enter' } as any)} />
            </nav>
        </>
    )
}

export default ChatRoom
