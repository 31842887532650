import React, { useState, useEffect } from "react";
import { moodStates } from "./moodStates";
import sql from "../../common/sql";
import { MoodSubmissionsEntity } from "../../../../backend/src/shared/entity";
import { useUser } from "../../hooks/useUser";

const MoodRing: React.FC = () => {
  const [moodValues, setMoodValues] = useState<number[]>(
    new Array(moodStates.length).fill(0)
  );
  const [model, setModel] = useState<MoodSubmissionsEntity>(
    {} as MoodSubmissionsEntity
  );
  const { user } = useUser();
  const [hasSubmittedToday, setHasSubmittedToday] = useState(false);

  useEffect(() => {
    const checkSubmission = async () => {
      const result = await sql.checkTodaysSubmition({ NumeUser: user.name });
      // Check if the result is an empty array
      setHasSubmittedToday(Array.isArray(result) && result.length > 0);
      console.log("result", result);
      console.log("user", user.name);
    };
  
    checkSubmission();
  }, [user.name]);

  const handleSliderChange = (index: number, value: number) => {
    const updatedMoodValues = [...moodValues];
    updatedMoodValues[index] = value;
    setMoodValues(updatedMoodValues);
    setModel((prevModel) => ({
      ...prevModel,
      [`MoodValue${index + 1}`]: value,
    }));
  };

  const handleSave = async () => {
    const updatedModel = {
      ...model,
      NumeUser: user.name,
      DataCompletare: new Date().toISOString(),
    };

    try {
      await sql.saveEntity("dbo.MoodSubmissions", updatedModel);
      alert("Your mood choices have been submitted successfully!");
      setHasSubmittedToday(true); // Update the submission status
    } catch (error) {
      console.error("Error saving mood submission:", error);
      alert("Failed to submit your mood choices. Please try again.");
    }
  };

  if (hasSubmittedToday) {
    return (
      <div className="container text-center">
        {" "}
        <p className="lead">
          Closing your rings everyday helps you track your balance between
          enjoying the spontaneity and weighing your concerns.
        </p>
        <p className="lead">
           Thank you for
          sharing your mood!
        </p>
        
      </div>
    );
  }
  return (
    <div className="container text-center">
      <p className="lead">
        Using a mood ring to guide your choices can be a playful and creative
        way to connect with your feelings.
      </p>
      <p className="lead">
        It's a fun method to reflect on how you're feeling at the moment.
      </p>
      <p className="lead"> Please don't forget to make your choices today!</p>
      {moodStates.map((moodState, index) => (
        <div key={moodState.label} className="mood-state-container">
          <h2 className="mood-label">{moodState.label}</h2>
          <p className="mood-option">
            {moodValues[index] === 0
              ? "Move the slider to see more answers"
              : moodState.options[moodValues[index] - 1]}
          </p>
          <div className="slider-container">
            <input
              type="range"
              className="styled-slider"
              min="0"
              max="5"
              value={moodValues[index]}
              onChange={(e) =>
                handleSliderChange(index, parseInt(e.target.value, 10))
              }
            />
            <div className="slider-ticks">
              {Array.from({ length: 6 }, (_, i) => (
                <span key={i} className="tick-mark"></span>
              ))}
            </div>
          </div>
        </div>
      ))}
      <button onClick={handleSave} className="btn btn-primary mt-3">
        Submit Choices
      </button>
    </div>
  );
};

export default MoodRing;
