import React from 'react'

const MottoImage = () => {
    return (
        <div className="d-flex justify-content-center">
            <img src="/images/mentor-logo.png" className="img nav-logo" alt="" />
        </div>
    )
}

export default MottoImage