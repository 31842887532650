import React from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import Header from './Header'
import HomePage from "./../pages/HomePage";
import RegisterPage from "./../pages/user/RegisterPage";
import PasswordRecoverPage from "./../pages/user/PasswordRecoverPage";
import PasswordResetPage from "./../pages/user/PasswordResetPage";
import ValidateEmailPage from "./../pages/user/ValidateEmailPage";
import LoginPage from '../pages/user/LoginPage';
import { UserRole } from '../common/user';
import { useUser } from '../hooks/useUser';
import Forbidden from './Forbidden';
// import InProgressAlert from './InProgressAlert';
import Footer from './Footer';
import MoodRing from './MoodRing/MoodRing';
import Chat from './Chat/Chat';
import About from './About/About';
import Sessions from './Session/Sessions';
import Resources from './Resources/Resources';
import Calendar from './Calendar';
import CoordinatorTeamMembers from '../pages/coordinator/CoordinatorTeamMembers';
import CoordinatorTeamRooms from '../pages/coordinator/CoordinatorTeamRooms';
import ContactUs from './About/ContactUs';
import EuFunding from './About/EuFunding';
import MentorPlus from './About/MentorPlus';
import Partnership from './About/Partnership';
import ChildProtectionPolicy from './About/ChildProtectionPolicy';
import ApproveCoordinator from '../pages/admin/ApproveCoordinator';
import AccountAvatar from './AccountAvatar';
import AccountConfig from './AccountConfig';
import Account from './Account';
import AccountPassword from './AccountPassword';
import InstallPage from './InstallPage';
import MentorSessions from './Session/MentorSessions';
import MentoringRelationship from '../pages/coordinator/MentoringRelationship';
import MentoringRelationshipForm from '../pages/coordinator/MentoringRelationshipForm';
import MentoringIs from './About/MentoringIs';
import MentoringSessionPrevNextMeeting from './Session/MentoringSessionPrevNextMeeting';
import FeedbackForm from './Feedback/FeedbackForm';
import Feedback from './Feedback/Feedback';
import FeedbackDetail from './Feedback/FeedbackDetail';
import { MessagesProvider } from '../hooks/useMessages';
import Iframe from './Iframe';
import MentoringSessionMeetingsCalendar from './Session/MentoringSessionMeetingsCalendar';
import PDFViewer from './PDFViewer';

function Auth(component: JSX.Element, ...roles: UserRole[]) {
    const { user } = useUser();
    roles = roles.length ? roles : ["Admin", "Coordonator", "Mentor", "Mentorat"];
    if (!user?.email) return <LoginPage />;
    if (!roles.includes(user.role)) return <Forbidden />;
    return component;
}

const Router = () => {
    const { user } = useUser();
    return (
        <BrowserRouter>
            <MessagesProvider>
                <Header />
                {/* <InProgressAlert /> */}
                <main className={`container ${user.email ? 'user' : 'guest'}`}>
                    <Routes>
                        <Route index element={<HomePage />} />
                        <Route path="/home" element={<HomePage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route
                            path="/password-recover"
                            element={<PasswordRecoverPage />}
                        />
                        <Route
                            path="/password-reset/:token"
                            element={<PasswordResetPage />}
                        />
                        <Route
                            path="/validate-email/:token"
                            element={<ValidateEmailPage />}
                        />
                        <Route path="/preview/:fileUrl" element={<PDFViewer />} />
                        <Route path="/preview/:fileUrl/:fileName" element={<PDFViewer />} />
                        <Route path="/account" element={Auth(<Account />)} />
                        <Route path="/account-config" element={Auth(<AccountConfig />)} />
                        <Route path="/account-avatar" element={Auth(<AccountAvatar />)} />
                        <Route path="/account-password" element={Auth(<AccountPassword />)} />
                        <Route path="/mentor-session" element={Auth(<MentorSessions />)} />
                        <Route path="/about" element={Auth(<About />)} />
                        <Route path="/about/mentoring-is" element={Auth(<MentoringIs />)} />
                        <Route path="/about/partnership" element={Auth(<Partnership />)} />
                        <Route path="/about/child-protection-policy" element={Auth(<ChildProtectionPolicy />)} />
                        <Route path="/about/contact-us" element={Auth(<ContactUs />)} />
                        <Route path="/about/eu-funding" element={Auth(<EuFunding />)} />
                        <Route path="/about/mentor-plus" element={Auth(<MentorPlus />)} />
                        <Route path="/sessions" element={Auth(<Sessions />)} />
                        <Route path="/sessions/:date" element={Auth(<Sessions />)} />
                        <Route path="/resources" element={Auth(<Resources />)} />
                        <Route path="/calendar" element={Auth(<Calendar />)} />
                        <Route path="/mood-ring" element={Auth(<MoodRing />)} />
                        <Route path="/chat/:uuid" element={Auth(<Chat />)} />
                        <Route path="/chat" element={Auth(<Chat />)} />
                        {/* Admin routes */}
                        <Route path="/admin/approve-coordinator" element={Auth(<ApproveCoordinator />, "Admin")} />
                        {/* Coordinator routes */}
                        <Route path="/coordinator/team-rooms/:id/:role/:name" element={Auth(<CoordinatorTeamRooms />, "Coordonator", "Admin")} />
                        <Route path="/coordinator/team-members" element={Auth(<CoordinatorTeamMembers />, "Coordonator", "Admin")} />
                        <Route path="/install" element={<InstallPage />} />
                        <Route path="/moodring" element={<MoodRing />} />
                        <Route path="/coordinator/mentoring-relationship" element={Auth(<MentoringRelationship />, "Coordonator", "Admin")} />
                        <Route path="/coordinator/mentoring-relationship/:idMentoring/:idMentee" element={Auth(<MentoringRelationshipForm />, "Coordonator", "Admin")} />
                        <Route path="/mentoring-calendar/:idMentoring/:index" element={Auth(<MentoringSessionMeetingsCalendar />, "Mentor", "Mentorat")} />
                        <Route path="/mentoring/select-mentee/:date" element={Auth(<MentorSessions />, "Mentor")} />
                        {/* <Route path="/mentoring/session/:idMentoring/:idMentoringSession" element={Auth(<MentorSessionsCalendar />)} />
                    <Route path="/mentoring/session/:idMentoring/:idMentoringSession/:date" element={Auth(<MentorSessionsCalendar />)} /> */}
                        <Route path="/mentoring/:idMentoring" element={Auth(<MentoringSessionPrevNextMeeting />)} />
                        <Route path="/mentoring/:idMentoring/:date" element={Auth(<MentoringSessionPrevNextMeeting />)} />
                        <Route path="/feedback" element={Auth(<Feedback />)} />
                        <Route path="/feedback/:idUserFrom" element={Auth(<FeedbackDetail />)} />
                        <Route path="/feedback-form/:idParentFeedback" element={Auth(<FeedbackForm />)} />
                        <Route path="/feedback-form/:idParentFeedback/:idUserTo" element={Auth(<FeedbackForm />)} />
                        <Route path="/feedback-form/:idParentFeedback/:idUserTo/:idMentoringSession" element={Auth(<FeedbackForm />)} />
                    </Routes>
                </main>
                <Footer />
            </MessagesProvider>
        </BrowserRouter>
    )
}

export default Router