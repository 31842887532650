import { createContext, useContext, useState } from "react";
import { Language } from "../common/lang";

const LanguageContext = createContext<{
  lang: Language;
  setLang: (lang: Language) => void;
}>(null as any);

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [lang, setLang] = useState<Language>(getUserLang());
  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </LanguageContext.Provider>
  );
}

export const useLang = () => {
  const { lang, setLang } = useContext(LanguageContext);
  return { lang, setLang: (lang: Language) => setUserLang(lang).then(() => setLang(getUserLang())) };
};

export const getUserLang = () =>
  (JSON.parse(localStorage.getItem("userInfo") || '{}').lang || "en") as Language;

export const setUserLang = async (lang: Language) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || '{}');
  userInfo.lang = lang;
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
};
