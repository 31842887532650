import React from 'react'
import Motto from '../Motto'
import Button from '../Button'
import { useTranslate } from '../../common/lang';
import ResourcesLinks from './ResourcesLinks';
import Title from '../Title';

const Resources = () => {
    return (
        <div className='row'>
            <Title text='Resurse' />
            <div className="col-12 px-4">
                {/* <Button>Exercise 1: Do our feelings deceive us?</Button>
                <Button>Exercise 2: Our Support Base</Button>
                <Button color='btn-secondary'>Exercise 3: Will be unlocked soon !</Button>
                <Button color='btn-secondary'>Exercise 4: Not available yet</Button> */}
                <ResourcesLinks />
            </div>
        </div>
    )
}

export default Resources
