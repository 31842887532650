
import { EntityName, EntityType } from "../../../backend/src/shared/entity";
import {API_URL} from "./env";

export type SqlError = { message: string, number: string | number };

export class SqlService {
  async handleResponse(response: Response) {
    let responseJson, responseText;
    const contentType = response.headers.get("content-type");
    if (contentType && ~contentType.indexOf("application/json")) {
      responseJson = await response.json();
    } else {
      responseText = await response.text();
    }
    if (response.status === 401) {
      if (window.location.pathname.includes("/login")) return;
      window.location.href = `/login/${encodeURIComponent(window.location.pathname)}/401`;
    } else if (response.status >= 400) {
      throw responseJson || responseText;
    }
    return responseJson || responseText;
  }
  async deleteEntity(
    table: EntityName,
    id: string | number
  ) {
    const response = await fetch(`${API_URL}/entity?table=${encodeURIComponent(table)}&id=${encodeURIComponent(id)}`, {
      method: "DELETE",
      credentials: 'include'
    });
    return this.handleResponse(response);
  }
  async saveEntity<T extends EntityName>(
    table: T,
    params: { [key: string]: string | number | Date | boolean | undefined | null }
  ): Promise<EntityType<T>> {
    const response = await fetch(`${API_URL}/entity`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ table, params }),
    });
    return this.handleResponse(response);
  }
  async query(
    query: string,
    params?: { [key: string]: string | number | Date | boolean | undefined | null }
  ) {
    const response = await fetch(`${API_URL}/query`, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query, params }),
    });
    return this.handleResponse(response);
  }
  getEntity<T extends EntityName>(entityName: T, entityKey: number) {
    return this.query('getEntity', { entityName, entityKey }) as Promise<EntityType<T>>;
  } 
  loginUserQuery(params: { email: string | number | Date | boolean | undefined | null, password: string | number | Date | boolean | undefined | null, role: string | number | Date | boolean | undefined | null }) {
    return this.query('loginUserQuery', params);
  }
  registerUserQuery(params: { name: string | number | Date | boolean | undefined | null, email: string | number | Date | boolean | undefined | null, password: string | number | Date | boolean | undefined | null, role: string | number | Date | boolean | undefined | null, idCountry: string | number | Date | boolean | undefined | null, idPartner: string | number | Date | boolean | undefined | null }) {
    return this.query('registerUserQuery', params);
  }
  passwordRecoverQuery(params: { email: string | number | Date | boolean | undefined | null }) {
    return this.query('passwordRecoverQuery', params);
  }
  validateTokenQuery(params: { token: string | number | Date | boolean | undefined | null }) {
    return this.query('validateTokenQuery', params);
  }
  loggedUserQuery() {
    return this.query('loggedUserQuery');
  }
  adminDashboardQuery() {
    return this.query('adminDashboardQuery');
  }
  getUsersByRoleQuery(params: { role: string | number | Date | boolean | undefined | null }) {
    return this.query('getUsersByRoleQuery', params);
  }
  getUsersQuery() {
    return this.query('getUsersQuery');
  }
  mentoringSessionByIdUserQuery(params: { idUser: string | number | Date | boolean | undefined | null }) {
    return this.query('mentoringSessionByIdUserQuery', params);
  }
  mentoringDetailsByIdMentoringQuery(params: { idMentoring: string | number | Date | boolean | undefined | null }) {
    return this.query('mentoringDetailsByIdMentoringQuery', params);
  }
  mentorRelationshipQuery(params: { idMentor: string | number | Date | boolean | undefined | null }) {
    return this.query('mentorRelationshipQuery', params);
  }
  lastMentoringSessionByIdMentoringQuery(params: { idMentoring: string | number | Date | boolean | undefined | null }) {
    return this.query('lastMentoringSessionByIdMentoringQuery', params);
  }
  mentoringSessionsByIdMenteeQuery(params: { idMentee: string | number | Date | boolean | undefined | null }) {
    return this.query('mentoringSessionsByIdMenteeQuery', params);
  }
  mentoringSessionByIdMentoringQuery(params: { idMentoring: string | number | Date | boolean | undefined | null }) {
    return this.query('mentoringSessionByIdMentoringQuery', params);
  }
  menteeByIdMentoringQuery(params: { idMentoring: string | number | Date | boolean | undefined | null }) {
    return this.query('menteeByIdMentoringQuery', params);
  }
  mentoringRelationshipQuery(params: { idCoordinator: string | number | Date | boolean | undefined | null }) {
    return this.query('mentoringRelationshipQuery', params);
  }
  availableMentorsQuery(params: { idCoordinator: string | number | Date | boolean | undefined | null }) {
    return this.query('availableMentorsQuery', params);
  }
  availableMenteesQuery(params: { idCoordinator: string | number | Date | boolean | undefined | null, idMentee: string | number | Date | boolean | undefined | null }) {
    return this.query('availableMenteesQuery', params);
  }
  calendarQuery() {
    return this.query('calendarQuery');
  }
  messagesForChatQuery(params: { loginUuid: string | number | Date | boolean | undefined | null, otherUuid: string | number | Date | boolean | undefined | null }) {
    return this.query('messagesForChatQuery', params);
  }
  coordinatorTeamQuery(params: { idUser: string | number | Date | boolean | undefined | null }) {
    return this.query('coordinatorTeamQuery', params);
  }
  roommatesByIdQuery(params: { id: string | number | Date | boolean | undefined | null }) {
    return this.query('roommatesByIdQuery', params);
  }
  partnerQuery() {
    return this.query('partnerQuery');
  }
  countryQuery() {
    return this.query('countryQuery');
  }
  roommatesQuery(params: { loginUuid: string | number | Date | boolean | undefined | null }) {
    return this.query('roommatesQuery', params);
  }
  myAccountQuery(params: { uuid: string | number | Date | boolean | undefined | null }) {
    return this.query('myAccountQuery', params);
  }
  getPartnersQuery() {
    return this.query('getPartnersQuery');
  }
  checkTodaysSubmition(params: { NumeUser: string | number | Date | boolean | undefined | null }) {
    return this.query('checkTodaysSubmition', params);
  }
  feedbackDetailQuery(params: { idUser: string | number | Date | boolean | undefined | null, idUserFrom: string | number | Date | boolean | undefined | null }) {
    return this.query('feedbackDetailQuery', params);
  }
  feedbackSummaryQuery(params: { idUser: string | number | Date | boolean | undefined | null }) {
    return this.query('feedbackSummaryQuery', params);
  }
  unreadFeedbackQuery(params: { uuid: string | number | Date | boolean | undefined | null }) {
    return this.query('unreadFeedbackQuery', params);
  }
  feedbackUserQuery(params: { idUserTo: string | number | Date | boolean | undefined | null, idUser: string | number | Date | boolean | undefined | null }) {
    return this.query('feedbackUserQuery', params);
  }
  
}

const sql = new SqlService()
export default sql;
