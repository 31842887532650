import React from 'react'
import { CountriesSymbol, MenteeResourceLang, MentorResourceLang, useResourceTranslate } from '../../common/lang';
import { useUser } from '../../hooks/useUser';
import { MenteeResourceLink, MentorResourceLink } from '../../common/resources';
import { useLang } from '../../hooks/useLang';
import Iframe from '../Iframe';
import { Link } from 'react-router-dom';

const ResourcesLinks = () => {
    const translate = useResourceTranslate();
    const { user } = useUser();
    const { lang } = useLang();
    const [view, setView] = React.useState<any>();
    const resources = Object.keys(user.role === "Mentorat" ? MenteeResourceLang : MentorResourceLang);
    const linkOf = (r: string) => {
        const source = user.role === "Mentorat" ? MenteeResourceLink : MentorResourceLink;
        const base = user.role === "Mentorat" ? 'Mentee' : 'Mentor';
        const country = (source as any)[r][CountriesSymbol[lang]].filename ? CountriesSymbol[lang] : CountriesSymbol.en;
        const filename = (source as any)[r][country].filename;
        return encodeURIComponent(`/resources/${base}/${r}/${country}/${filename}`);
    };
    return (
        <>
            {view && <Iframe file={linkOf(view)} />}
            {!view && <ul className='nav flex-column'>
                {resources.map(r => (
                    <li key={r} className='nav-link'>
                        {/* <a target='_blank' href={linkOf(r)}>{translate(r as any)}</a> */}
                        <Link to={`/preview/${linkOf(r)}/${translate(r as any)}`}>{translate(r as any)}</Link>
                    </li>
                ))}
            </ul>
            }
        </>
    )
}

export default ResourcesLinks
