import React from 'react'
import { MentoringSessionEntity } from '../../../../backend/src/shared/entity'
import { localeDateTime } from '../../common/utils'
import Fa from '../FontAwesome'
import { useTranslate } from '../../common/lang'
import Lang from '../Lang'

const MentoringSessionNext = ({ nextSession = {} as MentoringSessionEntity }) => {
    const translate = useTranslate();
    return (
        <>
            {translate("Următoarea sesiune va fi pe")} : <b>{nextSession.DateProposal && localeDateTime(nextSession.DateProposal)}</b>
            <div className="row fw-bold">
                {nextSession && nextSession.Location === 1 && <a href={nextSession.LinkOrAddress} target='_blank' rel='noreferrer'><Fa regular='link fa-fw' /><Lang text="Link-ul sesiunii online" /></a>}
                {nextSession && nextSession.Location === 2 && <span><Fa regular='location-dot fa-fw' /> {nextSession.LinkOrAddress}</span>}
            </div>
        </>
    )
}

export default MentoringSessionNext