import { UserEntity } from "../../../backend/src/shared/entity";
import { getUserLang } from "../hooks/useLang";
import { API_URL } from "./env";
import { Language } from "./lang";

export const passwordReset = async (email: string, password: string) => {
  const response = await fetch(`${API_URL}/passwordReset`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  let responseJson = await response.json();
  if (response.status === 400) {
    throw responseJson;
  }
  return responseJson;
};

export const passwordRecover = async (email: string) => {
  const response = await fetch(`${API_URL}/passwordRecover`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });
  let responseJson = await response.json();
  if (response.status === 400) {
    throw responseJson;
  }
  return responseJson;
};

export const loginUser = async (body: LoginInfo) => {
  const response = await fetch(`${API_URL}/loginUser`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "language": getUserLang(),
    },
    body: JSON.stringify(body),
  });
  let responseJson = await response.json();
  if (response.status === 400) {
    throw responseJson;
  }
  responseJson.language = getUserProp("language") as Language;
  localStorage.setItem("userInfo", JSON.stringify(responseJson));
  return responseJson;
};

export const registerUser = async (body: RegisterInfo) => {
  const response = await fetch(`${API_URL}/registerUser`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "language": getUserLang(),
    },
    body: JSON.stringify(body),
  });
  let responseJson = await response.json();
  if (response.status === 400) {
    throw responseJson;
  }
  return responseJson;
};

export const logoutUser = async () => {
  const response = await fetch(`${API_URL}/logoutUser`);
  localStorage.removeItem("userInfo");
  return await response.json();
};

export const getUserProp = (name: keyof UserInfo) => {
  const localStorageUser = localStorage.getItem("userInfo")||"{}";
    const userInfo = JSON.parse(localStorageUser);
    return userInfo[name];
}

export const getUserInfo = (): UserInfo => ({
  email: getUserProp("email"),
  name: getUserProp("name"),
  role: getUserProp("role") as UserRole,
  token: getUserProp("token"),
  uuid: getUserProp("uuid"),
  language: getUserProp("language") as Language,
  isAdmin: getUserProp("role") === "Admin" as UserRole,
  isCoordonator: getUserProp("role") === "Coordonator" as UserRole,
  isMentor: getUserProp("role") === "Mentor" as UserRole,
  isMentorat: getUserProp("role") === "Mentorat" as UserRole,
  hashkey: Math.random().toString(36).substring(2),
});

export type UserInfo = {
  role: UserRole;
  email: string;
  name: string;
  token: string;
  uuid: string;
  language: Language;
  isAdmin: boolean;
  isCoordonator: boolean;
  isMentor: boolean;
  isMentorat: boolean;
  account?: UserEntity;
  hashkey: string;
};

export type UserRole = "Admin" | "Coordonator" | "Mentor" | "Mentorat";

export type LoginInfo = {
  email: string;
  password?: string;
  role?: UserRole;
};

export type RegisterInfo = {
  firstName: string;
  lastName: string;
  role: UserRole;
  idCountry: number;
  idPartner: number;
  name: string;
  email: string;
  password: string;
  passwordRepeat: string;
};
