import React from 'react'
import { useUser } from '../../hooks/useUser';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import sql from '../../common/sql';
import { MentoringSessionEntity } from '../../../../backend/src/shared/entity';
import Loading from '../Loading/Loading';
import Lang from '../Lang';
import { localeDateTime } from '../../common/utils';
import MentoringSessionDateLocationForm from './MentoringSessionDateLocationForm';
import Fa from '../FontAwesome';
import Title from '../Title';
import { Labels, useTranslate } from '../../common/lang';

const MentoringSessionMeetingsCalendar = () => {
    const { user } = useUser();
    const { idMentoring = 0 } = useParams();
    const [index, setIndex] = React.useState<number>(+(useParams().index || 0));
    const translate = useTranslate();
    const [requestChage, setRequestChange] = React.useState(false);
    const [open, setOpen] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const [model, setModel] = React.useState<MentoringSessionEntity & { __index?: any }>({} as MentoringSessionEntity);
    const mentoring = useQuery({
        queryKey: ['mentoringEntityQuery', idMentoring],
        queryFn: () => sql.getEntity('dbo.Mentoring', +idMentoring)
    });
    const atendee = useQuery({
        queryKey: ['atendeeEntityQuery', idMentoring],
        queryFn: () => sql.getEntity('dbo.User', (user.role === 'Mentor' ? mentoring.data?.IdMentee : mentoring.data?.IdMentor) || 0),
        enabled: !!mentoring.data?.Id
    });
    const sessions = useQuery({
        queryKey: ['mentoringSessionByIdMentoringQuery', idMentoring],
        queryFn: () => sql.mentoringSessionByIdMentoringQuery({ idMentoring }) as Promise<MentoringSessionEntity[]>,
    });
    if (mentoring.isLoading || sessions.isLoading || atendee.isLoading || saving) return <Loading />;
    const minDateTomorow = (date: Date) => {
        const tomorow = new Date();
        tomorow.setDate(tomorow.getDate() + 1);
        return date.getTime() < tomorow.getTime() ? tomorow : date;
    }
    const minDate = new Date(sessions.data?.[index - 1]?.DateProposal || mentoring.data!.DataStart);
    const maxDate = new Date(sessions.data?.[index + 1]?.DateProposal || mentoring.data!.DataStop);
    if (model.__index !== index) {
        const model = sessions.data?.[index] || { DateProposal: minDateTomorow(minDate), IdMentoring: idMentoring } as MentoringSessionEntity;
        setModel({ ...model, __index: index });
    }
    const sessionsKeys = Array(mentoring.data?.Sessions || 0).keys();

    const sessionDetail = (index: number, session?: MentoringSessionEntity) => {
        return (
            <>
                # {index + 1} — {session ? `${localeDateTime(session.DateProposal)}` : <Lang text="Data și locul" />}
                <Fa duotone={session?.MenteeAccepted && session?.MentorAccepted ? 'check-circle text-success fa-lg mx-2' : 'question-circle text-danger fa-lg mx-2'} />
                {session && session.LinkOrAddress ?
                    <div className='text-muted ms-5'>
                        {<Fa regular={session.Location === 2 ? 'location-dot fa-fw me-2' : 'link fa-fw me-2'} />}
                        {session.Location === 2 ? session.LinkOrAddress : translate('Link-ul sesiunii online')}
                    </div> : ''
                }
            </>
        );
    }
    const handleSave = async () => {
        setSaving(true);
        sql
            .saveEntity('dbo.MentoringSession', { ...model, MentorAccepted: user.role === 'Mentorat' ? null : new Date(), MenteeAccepted: user.role === 'Mentor' ? null : new Date(), __index: undefined } as MentoringSessionEntity)
            .then(() => sessions.refetch())
            .then(() => { setOpen(false); setRequestChange(false); })
            .finally(() => setSaving(false));
    }
    const requestChageDate = (session: MentoringSessionEntity) => {
        return requestChage || !session.Id || (session.MenteeAccepted && user.role === 'Mentorat') || (session.MentorAccepted && user.role === 'Mentor');
    }
    return (
        <ul className='nav flex-column'>
            <li>
                <Title text={atendee.data?.Name as Labels} />
            </li>
            {
                [...sessionsKeys].map(i => (
                    <li key={i} className='nav-item'>
                        <Link className='nav-link' to='' onClick={e => [e.preventDefault(), setIndex(i), setOpen(true)]}>
                            {sessionDetail(i, sessions.data?.[i])}
                        </Link>
                    </li>
                ))
            }
            {open && requestChageDate(model) &&
                <li>
                    <div className='modal fade show d-block' tabIndex={-1} aria-modal={true} role='dialog'>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body alert alert-warning m-0" role="alert">
                                    <MentoringSessionDateLocationForm
                                        model={model}
                                        setModel={setModel}
                                        sessionIndex={index + 1}
                                        minDate={minDateTomorow(minDate)}
                                        maxDate={maxDate}
                                        handleSave={handleSave}
                                        handleCancel={() => [setOpen(false), setRequestChange(false)]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            }
            {open && !requestChageDate(model) &&
                <li>
                    <div className='modal fade show d-block' tabIndex={-1} aria-modal={true} role='dialog'>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className='fs-6'>{sessionDetail(index, model)}</h6>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={e => [e.preventDefault(), setOpen(false)]}></button>
                                </div>
                                <div className="modal-body alert alert-warning m-0" role="alert">
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <button className='btn btn-link' onClick={e => [e.preventDefault(), handleSave()]}>{translate('Acceptă data propusă')}</button>
                                            <button className='btn btn-link' onClick={e => [e.preventDefault(), setRequestChange(true)]}>{translate('Solicit modificare')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            }
        </ul>
    )
}

export default MentoringSessionMeetingsCalendar