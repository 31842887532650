import {
  ChangeEvent,
  SyntheticEvent,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoginContainer from "../../components/LoginContainer";
import ErrorBox from "../../components/ErrorBox";
import { SqlError } from "../../common/sql";
import { useUser } from "../../hooks/useUser";
import { LoginInfo, loginUser } from "../../common/user";
import Button from "../../components/Button";
import Fa from "../../components/FontAwesome";
import { useTranslate } from "../../common/lang";
import Lang from "../../components/Lang";

const LoginPage = () => {
  const { redirect } = useParams();
  const navigate = useNavigate();
  const { setUser } = useUser();
  const translate = useTranslate();
  const [error, setError] = useState<SqlError>();
  const [model, setModel] = useState<LoginInfo>({
    email: "",
    password: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModel((model) => ({ ...model, [e.target.name]: e.target.value }));
    setError(undefined);
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    loginUser(model)
      .then(() => {
        setUser();
        navigate(decodeURIComponent(redirect || "/home"));
      })
      .catch(setError);
  };

  const labels = {
    username: translate("Email"),
    password: translate("Parolă"),
  };
  return (
    <LoginContainer>
        <div className="form-floating mb-3">
          <input type="email" name="email" id="email" value={model.email} onChange={handleChange} placeholder={labels.username} />
          <label htmlFor="email">{labels.username}</label>
        </div>

        <div className="form-floating mb-3">
          <input type="password" name="password" id="password" value={model.password} onChange={handleChange} placeholder={labels.password} />
          <label htmlFor="password">{labels.password}</label>
        </div>

        <ErrorBox>{error}</ErrorBox>

        <Button onClick={handleSubmit}>
          <Lang text="Intră în cont" />
        </Button>
        <Button to="/password-recover">
          <Lang text="Recuperare parolă" />
        </Button>
        <Button to="/home" color="btn-outline-warning">
          <Fa duotone="circle-arrow-left fa-xl fa-fw" /> <Lang text="Pagina anterioară" />
        </Button>
    </LoginContainer>
  );
};

export default LoginPage;
